import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import '../loginpage/login-page.css';
import './register-page.css';
import { HOST, COOKIES } from '../../constants.js';
import Loader from '../loader/loader.js';


class RegisterPage extends Component {

    constructor(props) {
        super(props)
        this.state = {errLoginMess: '', errEmail: false, errLogin: false, 
                      errPassOneInput: false, errPassTwoInput: false,
                      registerComplete: false, load: false
                    }
        this.emailInput = React.createRef();
        this.loginInput = React.createRef();
        this.passOneInput = React.createRef();
        this.passTwoInput = React.createRef();
    }

    componentDidMount() {
        let cat = COOKIES.get('jwt_token')
        if (cat) {
            this.props.history.push('/')
        }
        document.title = 'Регистрация | Gamestatus'
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.registerHundler()
        }
    }

    registerHundler = () => {
        let emailValue = this.emailInput.current.value
        let loginValue = this.loginInput.current.value
        let passOneValue = this.passOneInput.current.value
        let passTwoValue = this.passTwoInput.current.value
        let isErr = false

        if (emailValue.length) {
            this.setState({'errEmail': false})
        } else {
            isErr = true
            this.setState({'errEmail': true})
        }

        if (loginValue.length) {
            this.setState({'errLogin': false})
        } else {
            isErr = true
            this.setState({'errLogin': true})
        }

        if (passOneValue.length) {
            this.setState({'errPassOneInput': false})
        } else {
            isErr = true
            this.setState({'errPassOneInput': true})
        }

        if (passTwoValue.length) {
            this.setState({'errPassTwoInput': false})
        } else {
            isErr = true
            this.setState({'errPassTwoInput': true})
        }

        if (passOneValue != passTwoValue) {
            this.setState({
                errLoginMess: 'Пароли должны совпадать',
                errPassOneInput: true, errPassTwoInput: true,
                load: false
            })
            return
        }
        if (isErr) {
            return
        }

        let data = {
            "email": emailValue,
            "username": loginValue,
            "password": passTwoValue
        }
        this.setState({load: true})
        fetch(HOST + 'auth/user/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }})
            .then((response) => {
                if (!response.ok) {
                    response.json().then((json) => {
                        let errObj = {errEmail: false, errLogin: false, errLoginMess: '', load: false}
                        let errUsername = json['username']
                        let errEmail = json['email']

                        if (errUsername) {
                            errObj.errLogin = true
                            errObj.errLoginMess += '\n ' +  errUsername
                        } else {
                            errObj.errLogin = false
                        }

                        if (errEmail) {
                            errObj.errEmail = true
                            errObj.errLoginMess += '\n ' +  errEmail
                        } else {
                            errObj.errEmail = false
                        }
                        this.setState(errObj)
                    })
                } else {
                    this.setState({registerComplete: true})
                }
            })
    }

    render() {
        if (this.state.registerComplete) {
            return (
                <div className='main'>
                    <div className='content'>
                        <Link to='/'>
                            <div className='sign-in-wellcome'>
                                <img className='sign-in-logo' src='/logo.svg' />
                                <p className='sign-in-title'>GAMESTATUS</p>
                            </div>
                        </Link>
                        <div className='sign-in-block register-block register-complete'>
                            <p className='sign-in-white-text'>Спасибо за регистрацию. Пожалуйста подтвердите ваш email.</p>
                            <Link to='/'><span className='sign-in-green-text'> На главную</span></Link>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className='main' onKeyDown={this.handleKeyDown}>
                <Helmet>
                    <link rel='canonical' href='https://gamestatus.info/registration' data-react-helmet='true' />
                </Helmet>
                <div className='content'>
                    <Link to='/'>
                        <div className='sign-in-wellcome'>
                            <img className='sign-in-logo' src='/logo.svg' />
                            <p className='sign-in-title'>GAMESTAT</p>
                        </div>
                    </Link>
                    <div className='sign-in-block register-block'>
                        <p className='sign-in-text'>Регистрация</p>
                        <input className={this.state.errEmail ? 'input-field red-borad' : 'input-field'} 
                            type='email' placeholder='Почта' ref={this.emailInput} />
                        <input className={this.state.errLogin ? 'input-field red-borad' : 'input-field'} 
                            placeholder='Логин' ref={this.loginInput} />
                        <input className={this.state.errPassOneInput ? 'input-field red-borad' : 'input-field'} 
                            type='password' placeholder='Пароль' ref={this.passOneInput} />
                        <input className={this.state.errPassTwoInput ? 'input-field red-borad' : 'input-field'} 
                            type='password' placeholder='Подтвердите пароль' ref={this.passTwoInput} />
                        <p className='err-text'>{this.state.errLoginMess}</p>
                        {this.state.load ? <Loader /> : <p className='sign-in-button' onClick={this.registerHundler}>Создать аккаунт</p>}
                        <p className='sign-in-white-text'>Вы уже зарегистрированы?
                            <Link to='/sign-in'><span className='sign-in-green-text'> Войти</span></Link>
                        </p>
                        <Link to='/reset-password'>
                            <p className='sign-in-white-text sign-in-green-text'>Восстановить пароль</p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default RegisterPage;