import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Adsense } from '@ctrl/react-adsense';
import { MdSentimentDissatisfied, MdSentimentSatisfied } from 'react-icons/md';

import '../game-calendar/game-calendar.css';
import TopBar from '../top-bar/top-bar.js';
import { HOST, COOKIES, REFLINK, STRINGLOCAL } from '../../constants.js';
import Loader from '../loader/loader.js';
import Store from '../../modules/store.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { returnRefGameImg, getAnotherLangPage } from '../utils';


class ReleasedGame extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false, currentSeason: ''
        }
        this.maxWidth = window.innerWidth;
        this.imgRefGame = returnRefGameImg(this.maxWidth);
        this.userLocale = this.props.userLang
    }

    showPopUp = () => {
        if (!this.firstClick) {
            let firstShow = parseInt(COOKIES.get('firstShow')) || 0
            let countShow = parseInt(COOKIES.get('countShow')) || 0
            if (!firstShow && countShow == 3) {
                COOKIES.remove('firstShow')
                COOKIES.remove('countShow')
                COOKIES.set('firstShow', 1)
                COOKIES.set('countShow', countShow +=1)
                this.setState({showPopUp: true})
            } else if (countShow >= 8) {
                COOKIES.remove('countShow')
                COOKIES.set('countShow', 0)
                this.setState({showPopUp: true})
            } 
            else {
                COOKIES.remove('countShow')
                COOKIES.set('countShow', countShow +=1)
            }
            this.firstClick = true
        }
    }

    clickOnRefGame = () => {
        fetch(HOST + 'gameinfo/game/countrefgame/')
    }

    componentDidMount() {
        this.getGameCalendar()
        this.showPopUp()
    }

    getGameCalendar() {
        const params =  {headers: {
            'Content-Type': 'application/json',
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,
        }}
        fetch(HOST + 'gameinfo/game/releasedgame/', params)
            .then(response => response.json())
            .then(json => {
                const nameSeasons = Array()
                for (const [key, value] of Object.entries(json.data)) {
                    if (value) {
                        nameSeasons.push(key)
                    }
                }
                Store.dispatch({type: 'RELEASEDGAMESEASONS', value: nameSeasons})
                Store.dispatch({type: 'RELEASEDGAME', value: json.data})
                Store.dispatch({type: 'SHOWRELEASEDGAME', value: json.data[nameSeasons[0]]})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.count_tg_users})
                this.setState({loaded: true, currentSeason: nameSeasons[0]})
            })
    }

    setActiveSeason(seasonsName) {
        Store.dispatch({type: 'SHOWRELEASEDGAME', value: this.props.releasedGame[seasonsName]})
        this.setState({currentSeason: seasonsName})
    }

    iconDetailGame(crackDate) {
        if (!crackDate) {
            return <p className="info-button info-button-usual not-cr-smile"><MdSentimentDissatisfied /></p>
        }
        else {
            return <p className="info-button info-button-usual"><MdSentimentSatisfied /></p>
        }
    }

    handlerSteamIcon(gameObj) {
        if (gameObj.steam_prod_id) {
            return <div className='steam-icon'><a target="_blank" href={`https://store.steampowered.com/app/${gameObj.steam_prod_id}`}><img src="https://gamestatus.s3.us-west-000.backblazeb2.com/Steam_icon_logo.svg+(1).png"/></a></div>
        }
    }
    

    render() {
        let countGame = 0
        const seasonTranslate = {
            'fall': STRINGLOCAL.fall, 'spring': STRINGLOCAL.spring,
            'summer': STRINGLOCAL.summer, 'winter': STRINGLOCAL.winter
        }
        if (!this.state.loaded) {
            return (
                <div className='main-wrapper'>
                    <div className="home-main-container">
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <div className='main-wrapper'>
                {/* {this.state.showPopUp ? <a href={REFLINK} target="_blank" className="ref-absolute-block " onClick={() => this.setState({showPopUp: false})}></a> : ''} */}
                <Helmet>
                    <title>{STRINGLOCAL.title_released_game}</title>
                    <link rel="alternate" hreflang={getAnotherLangPage(this.userLocale)} href={`https://gamestatus.info/releasedgame/${getAnotherLangPage(this.userLocale)}`} />
                    <link rel='canonical' href={`https://gamestatus.info/releasedgame/${this.userLocale}`} data-react-helmet='true' />
                    <meta property="og:description" content={STRINGLOCAL.released_game_desc} data-react-helmet='true' />
                    <meta name="description" content={STRINGLOCAL.released_game_desc} />
                    <meta property="og:image" content="https://gamestat.s3.eu-west-2.amazonaws.com/logo_preview.png" data-react-helmet='true' />
                    <meta property="og:title" content={STRINGLOCAL.title_released_game} data-react-helmet='true' />
                    <meta property="og:site_name" content={STRINGLOCAL.site_name} data-react-helmet='true' />
                    <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                </Helmet>
                <div className="home-main-container cracked-games">
                    <TopBar hideCalendar={true} />
                    <div id="advideo_adv_container"></div>
                    <img className="calendar-logo" src='/img_released_game.png' alt="График выхода игр на ПК 2021 год" />
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    <h1 className="calendar-title">{STRINGLOCAL.released_game_h1}</h1>
                    <div className="calendar-wrapper">
                        <ul className="list-month">
                            {
                                this.props.releasedGameSeasons.map((seasonsName, i) => {
                                    return (
                                        <li key={i} 
                                            className={this.state.currentSeason == seasonsName ? "selct-month" : ""}
                                            onClick={() => this.setActiveSeason(seasonsName)}
                                            >{seasonTranslate[seasonsName]}
                                        </li>
                                        )
                            })}
                        </ul>
                        <div className="calendar-game-wrapper">
                            <div className="describe-color">
                                <div className="blue-calendar"></div>
                                <span>{STRINGLOCAL.usual_game}</span>
                                <div className="red-calendar"></div>
                                <span>{STRINGLOCAL.aaa_game}</span>
                            </div>
                            <div className="game-in-calendar">
                                {this.props.showReleasedGame.map((obj, i) => {
                                    countGame += 1
                                    if (obj.is_AAA) {
                                        return (
                                            <div className="game-cl-us game-cl-aaa">
                                                <Link to={'/' + obj.slug + "/" + this.userLocale} key={i}>
                                                    <img className="calenad-img-game" src={obj.short_image} alt={`Дата выхода на ваш ПК ${obj.title}`} />
                                                </Link>
                                                <div className="game-cl-rl-date">
                                                    <img src="/calendar-1.svg" />
                                                    <p>{obj.release_date}</p>
                                                    {this.handlerSteamIcon(obj)}
                                                </div>
                                                {this.iconDetailGame(obj.crack_date)}
                                                <p className="num-game">#{countGame}</p>
                                            </div>
                                        )
                                    } else {
                                        return (
                                            <div className="game-cl-us">
                                                <Link to={'/' + obj.slug + "/" + this.userLocale} key={i}>
                                                    <p className="cl-title-game">{obj.title}</p>
                                                </Link>
                                                <div className="game-cl-rl-date">
                                                    <img src="/calendar-1.svg" />
                                                    <p>{obj.release_date}</p>
                                                    {this.handlerSteamIcon(obj)}
                                                </div>
                                                {this.iconDetailGame(obj.crack_date)}
                                                <p className="num-game">#{countGame}</p>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <h2 className="text-hot-game small-text">
                        {STRINGLOCAL.released_game_h2}  
                    </h2>
                    <BottomInfo />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang,
        releasedGameSeasons: state.releasedGameSeasons,
        releasedGame: state.releasedGame,
        showReleasedGame: state.showReleasedGame,
        countTgUsers: state.tgCountUsers,
    }
}

export default connect(mapStateToProps)(ReleasedGame);