import React, { Component } from 'react';

import { Adsense } from '@ctrl/react-adsense';

import { COOKIES } from '../../constants.js';

class MiddleAds extends Component {

    render() {
        let isActiveAds = COOKIES.get('is_active_ads');
        if (parseInt(isActiveAds) != 0) {
            return (
                <div className="div-middle-b-ads">
                    <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div>
                </div>
            )
        } else {
            return ""
        }
    }
}

export default MiddleAds;