import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { STRINGLOCAL, HOST, COOKIES } from '../../constants';


class DetailGameInfo extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notFound: false, loaded: false,
            isSubscribe: false, loadedImg: false,
        }
        this.userLocale = this.props.userLang
        this.jwt = COOKIES.get('jwt_token')
    }

    iterateProtections = () => {
        let strProtect = this.props.detail_game.protections
        strProtect = strProtect.replace('["', "").replace('"]', "").replace('[', "").replace(']', "")
        return strProtect.toUpperCase()
    }

    iterateCrackedGroups = () => {
        let crackedGroups = this.props.detail_game.hacked_groups
        crackedGroups = crackedGroups.replace('["', "").replace('"]', "").replace('[', "").replace(']', "")
        return crackedGroups.toUpperCase() || "---"
    }

    hundleSubscribe = () => {
        let obj = this.props.detail_game

        fetch(HOST + 'gameinfo/game/' + obj.id + '/subscribe/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + this.jwt
            }
        })
            .then((response) => {
                if (response.ok) {
                    document.location.reload();
                }
            })
    }

    calcStatusGame = () => {
        let currentdate = new Date()
        let releaseDate = Date.parse(this.props.detail_game.release_date)
        let crackDate = Date.parse(this.props.detail_game.crack_date)
        if (currentdate < releaseDate) {
            var gameStatus = this.userLocale.includes('ru') ? 'В разработке' : 'Developing'
            var gameStatusCss = 'game-unreleased'
        } else if (releaseDate && crackDate) {
            var gameStatus = this.userLocale.includes('ru') ? 'Взломана' : 'Cracked'
            var gameStatusCss = 'game-cracked'
        } else if (releaseDate && !crackDate) {
            var gameStatus = this.userLocale.includes('ru') ? 'Не взломана' : 'Not Cracked'
            var gameStatusCss = 'game-uncracked'
        }
        return {cssStatus: gameStatusCss, readebleStatus: gameStatus}
    }

    renderSteamBtn = (detailGame) => {
        if (detailGame.steam_prod_id) {
            return (
                <a href={`https://store.steampowered.com/app/${detailGame.steam_prod_id}`} target="_blank">
                    <div className='div-act-btn-wrap steam-btn'>
                        <p className='subscribe-button unsubscribe-button'>{STRINGLOCAL.gameInStore}</p>
                        <img src='https://gamestatus.s3.us-west-000.backblazeb2.com/Steam_icon_logo.svg+(1).png' />
                    </div>
                </a>
            )
        }
        return ""
    }

    renderActionBtn = () => {
        if (this.props.detail_game.torrent_link) {
            return (
                <div>
                    {this.props.isSubscribe ? <p className='subscribe-button unsubscribe-button sub-btn' onClick={this.props.is_auth ? this.hundleSubscribe : () => this.props.history.push('/sign-in')}>{STRINGLOCAL.is_subscribe}</p> : <p className='subscribe-button sub-btn' onClick={this.props.is_auth ? this.hundleSubscribe : () => this.props.history.push('/sign-in')}>{STRINGLOCAL.no_subscribe}</p>}
                    <div className='div-act-btn'>
                        <div>
                            <a href={this.props.detail_game.torrent_link} target="_blank">
                            <div className='div-act-btn-wrap'>
                                <p className='subscribe-button unsubscribe-button'>{STRINGLOCAL.downloadTorrent}</p>
                                <img src='https://f000.backblazeb2.com/file/GameStatus/uTorrent-Logo2.png' />
                            </div>
                            </a>
                        </div>
                        <div>
                            {this.renderSteamBtn(this.props.detail_game)}
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div>
                {this.props.isSubscribe ? <p className='subscribe-button unsubscribe-button' onClick={this.props.is_auth ? this.hundleSubscribe : () => this.props.history.push('/sign-in')}>{STRINGLOCAL.is_subscribe}</p> : <p className='subscribe-button' onClick={this.props.is_auth ? this.hundleSubscribe : () => this.props.history.push('/sign-in')}>{STRINGLOCAL.no_subscribe}</p>}
                <div className='div-act-btn'>
                    {this.renderSteamBtn(this.props.detail_game)}
                </div>
            </div>
        )
        
    }

    render() {
        return (
            <div className='info-game-block'>
                {window.innerWidth <= 1035 ?
                <img className={this.state.loadedImg ? 'img-loaded' : 'img-load'} onLoad={() => {this.setState({loadedImg: true})}} src={this.props.detail_game.short_image} alt={`Взлом ${this.props.detail_game.title}, дата выхода`} /> : 
                <img className={this.state.loadedImg ? 'img-loaded' : 'img-load'} onLoad={() => {this.setState({loadedImg: true})}} src={this.props.detail_game.short_image} alt={`Взлом ${this.props.detail_game.title}, дата выхода`} />}
                <div className='info-game-wrapper'>
                    <div className='title-info-top'>
                        <div className="top-title-info-game">
                            <div className='game-status protect-bottom'>
                                <p className='game-info-key'>{STRINGLOCAL.status}</p>
                                <p className={'game-info-def-text ' + this.calcStatusGame()["cssStatus"]}>{this.calcStatusGame()["readebleStatus"]}</p>
                            </div>
                            <div className='game-metascore'>
                                <p className='game-info-key'>{STRINGLOCAL.userscore}</p>
                                <p className={'rate ' + this.props.detail_game.userScoreCss}>{this.props.detail_game.user_score || '---'}</p>
                            </div>
                            <div className='game-info-releasedate'>
                                <p className='game-info-key'>{STRINGLOCAL.release_date}</p>
                                <p className='game-info-value'>{this.props.detail_game.release_date || '---'}</p>
                            </div>
                        </div>
                        <div className='game-readable-status'>
                            <p>{this.props.detail_game.readable_status}</p>
                        </div>
                    </div>
                    <div className="delimititer-game-info"></div>
                    <div className='title-info-top title-info-bottom'>
                        <div className='bottom-info-delimiter'>
                            <div className='game-info-releasedate protect-bottom'>
                                <p className='game-info-key'>{STRINGLOCAL.protect}</p>
                                <p className='game-info-value'>{this.iterateProtections()}</p>
                            </div>
                            <div className='game-info-releasedate'>
                                <p className='game-info-key'>{STRINGLOCAL.group}</p>
                                <p className='game-info-value'>
                                    {this.iterateCrackedGroups()}
                                </p>
                            </div>
                            <div className='game-info-releasedate'>
                                <p className='game-info-key'>{STRINGLOCAL.crack_date}</p>
                                <p className='game-info-value'>
                                    {this.props.detail_game.crack_date ? this.props.detail_game.crack_date : '---'}
                                </p>
                            </div>
                        </div>
                        {this.renderActionBtn()}
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(DetailGameInfo);
