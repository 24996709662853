import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router-dom";

import './top-bar.css'
import Store from '../../modules/store.js';
import { HOST, COOKIES, STRINGLOCAL } from '../../constants.js';
import CalendarDate from '../calendar-date/calendar-date.js';
import TelegramHeroBlock from './telegram_hero.js';
import { getLangAfterLoad, setLangUrl } from '../utils';


class TopBar extends Component {

    constructor(props) {
        super(props)
        this.state = {isAuth: false, username: '',}
        this.userLocale = this.props.userLang
    }

    getUserInfo = (jwtToken) => {
        fetch(HOST + 'auth/user/', {
            credentials: 'omit',
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'JWT ' + jwtToken,
            }
        })
            .then(response => response.json())
            .then(json => {
                let userName = json['username']
                COOKIES.set('username', userName, { path: '/' })
                this.setState({username: userName})
                 COOKIES.set('is_active_ads', json['is_active_ads'], { path: '/' })
            })
    }

    logoutHundler = () => {
        COOKIES.remove('username', { path: '/' })
        COOKIES.remove('jwt_token', { path: '/' })
        COOKIES.remove('csrftoken', { path: '/' })
        COOKIES.remove('sessionid', { path: '/' })
        Store.dispatch({type: 'AUTHUSER', value: false})
        this.props.history.push('/')
    }

    componentDidMount() {
        getLangAfterLoad()
        let jwtToken = COOKIES.get('jwt_token')

        if (jwtToken) {
            Store.dispatch({type: 'AUTHUSER', value: true})
            let userName = COOKIES.get('username')
            if (!userName) {
                this.getUserInfo(jwtToken)
            } else {
                this.setState({username: userName})
            }
        }
    }

    wait(time) {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, time);
        });
    }

    async getSearchResults(event) {
        let searchTitle = event.target.value
        if (!searchTitle) {
            Store.dispatch({type: 'SEARCHRESULT', value: []})
        }
        await this.wait(1000); //exluce ddos backend
        let foundGameList = []
        if (searchTitle && searchTitle.length >= 3) {
            fetch(HOST + 'gameinfo/game/search_title/', 
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({title: searchTitle})
                })
                .then(response => response.json())
                .then(json => {
                    for (let i = 0; i < json.length; i++) {
                        foundGameList.push(json[i])
                    }
                    Store.dispatch({type: 'SEARCHRESULT', value: foundGameList})
                })
        }
    }

    redirectFunc = (objGame) => {
        this.props.history.push('/' + objGame.slug)
        window.location.reload()
    }

    // showTgHero = () => {
        
    //     return(
    //         <a target="_blank" href="https://t.me/gamestatusinfo">
    //             <div className="news-home-prewiw tg-hero-bl hero-bl">
    //                 <div className="hero-bl-wrapper tg-block-info-l">
    //                     <p className="hero-title-tg">{STRINGLOCAL.tgHero}</p>
    //                     <div className="tg-hero-devider"></div>
    //                     <div className="tg-block-info-r">
    //                         <img src="/tg-icon.jpeg" />
    //                         <div className="chanel-title">
    //                             <p>GameStatusInfo</p>
    //                             <p>{this.props.countTgUsers + STRINGLOCAL.usersTG}</p>
    //                             <div className='tg-chanel-btn'>
    //                                 <p>View in Telegram</p>
    //                                 {/* <p>$ SUPPORT US</p> */}
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </div>
    //             </div>
    //         </a>
    //     )
    // }

    render() {
        const showHeroBool = this.props.showTgHero === undefined || this.props.showTgHero === true ? true : false
        var hideNews = true ? !["ru", "en"].includes(this.userLocale) : false
        if (this.userLocale.includes('ru')) {
            return (
            <div className="top-bar-main">
                <div className="top-bar-container">
                    <a href={'/' + this.userLocale} >
                        <div className="title-logo">
                            <img src="/logo_new.png" alt="gamestatus.info" />
                        </div>
                    </a>
                    <input 
                        className="search-bar" 
                        placeholder="Найти игру: star wars" 
                        type="text" 
                        onChange={(e) => {this.getSearchResults(e)}} 
                        onFocus={(e) => {this.getSearchResults(e)}} 
                        onBlur={() => setTimeout(() => {Store.dispatch({type: 'SEARCHRESULT', value: []})}, 500)}
                    />
                    <div className='searach-results-list'>
                        {this.props.search_result.map((obj, i) => {
                            return (
                                <div className='search-result' key={i} onClick={() => this.redirectFunc(obj)}>
                                    <p className='search-result-name'>{obj.title}</p>
                                </div>
                            )
                        })}
                    </div>
                    {!this.props.auth_user ? 
                        (
                            <div className='top-action-buttons'>
                                {hideNews ? '' : <Link to={'/news' + "/" + this.userLocale}><p className="calendar-button">НОВОСТИ</p></Link>}
                                {this.props.hideCalendar ? <CalendarDate /> :
                                    <div className='top-bar-calendar'>
                                        <Link to={'/gamecalendar' + "/" + this.userLocale}><p className="calendar-button">Календарь игр</p></Link>
                                    </div>
                                }
                                <Link to={'/sign-in/' + this.userLocale}><p className="sign-in-btn">{STRINGLOCAL.sign_in}</p></Link>
                            </div>
                        )
                        : (
                            <div className='top-action-buttons'>
                                {hideNews ? '' : <Link to={'/news' + "/" + this.userLocale}><p className="calendar-button">НОВОСТИ</p></Link>}
                                {this.props.hideCalendar ? <CalendarDate /> :
                                    <div className='top-bar-calendar'>
                                        <Link to={'/gamecalendar' + "/" + this.userLocale}><p className="calendar-button">Календарь игр</p></Link>
                                    </div> 
                                }
                                <Link to='/profile'>
                                    <div className='username-container'>
                                        <img src='/user-avatar.svg' />
                                        <p className='username'>{this.state.username.slice(0, 12)}</p>
                                    </div>
                                </Link>
                                <div className='loguot-icon' onClick={this.logoutHundler}>
                                    <img src='/logout-icon.svg' />
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="select-lang">
                    {/* <a href="https://docs.google.com/forms/d/e/1FAIpQLScvcVMOffS-PCOBU8dkfLf2CuZRQ7di92npRcCtvJ6L50pgYg/viewform" target="_blank" id="hide_add_game" className="add-game"><span>ДОБАВИТЬ ИГРУ</span></a> */}
                    <a href="https://gamestatus.info/privacy-policy" target="_blank" id="hide_add_game" className="add-game"><span>PRIVACY POLICY</span></a>
                    <p className={this.props.userLang == 'ru' ? "selected-lang" : ""} onClick={() => setLangUrl('ru')}>RU</p>
                    <p className={this.props.userLang == 'en' ? "selected-lang" : ""} onClick={() => setLangUrl('en')}>EN</p>
                    <p className={this.props.userLang == 'zh' ? "selected-lang" : ""} onClick={() => setLangUrl('zh')}>中國</p>
                </div>

                {this.props.countTgUsers && showHeroBool ? <TelegramHeroBlock countTgUsers={this.props.countTgUsers} /> : ''}
            </div>
        )}
        return (
            <div className="top-bar-main">
                <div className="top-bar-container">
                    <a href={'/' + this.userLocale}>
                        <div className="title-logo">
                            <img src="/logo_new.png" alt="gamestatus.info" />
                        </div>
                    </a>
                    <input 
                        className="search-bar" 
                        placeholder="Find: star wars" 
                        type="text" 
                        onChange={(e) => {this.getSearchResults(e)}} 
                        onFocus={(e) => {this.getSearchResults(e)}} 
                        onBlur={() => setTimeout(() => {Store.dispatch({type: 'SEARCHRESULT', value: []})}, 500)}
                    />
                    <div className='searach-results-list'>
                        {this.props.search_result.map((obj, i) => {
                            return (
                                <div className='search-result' key={i} onClick={() => this.redirectFunc(obj)}>
                                    <p className='search-result-name'>{obj.title}</p>
                                </div>
                            )
                        })}
                    </div>
                    {!this.props.auth_user ? 
                    (
                        <div className='top-action-buttons'>
                            {this.props.hideCalendar ? <CalendarDate /> :
                                <div className='top-bar-calendar'>
                                    {hideNews ? '' : <Link to={'/news' + "/" + this.userLocale}><p className="calendar-button">{STRINGLOCAL.news}</p></Link>}
                                    <Link to={'/gamecalendar' + "/" + this.userLocale}><p className="calendar-button">{STRINGLOCAL.game_calendar_top_bar_text}</p></Link>
                                </div>
                            }
                            <Link to={'/sign-in/' + this.userLocale}><p className="sign-in-btn">{STRINGLOCAL.sign_in}</p></Link>
                        </div>
                    )
                    : (
                        <div className='top-action-buttons'>
                            {hideNews ? '' : <Link to={'/news' + "/" + this.userLocale}><p className="calendar-button">{STRINGLOCAL.news}</p></Link>}
                            {this.props.hideCalendar ? <CalendarDate /> :
                                <div className='top-bar-calendar'>
                                    <Link to={'/gamecalendar' + "/" + this.userLocale}><p className="calendar-button">{STRINGLOCAL.game_calendar_top_bar_text}</p></Link>
                                </div>
                            }
                            <Link to='/profile'>
                                <div className='username-container'>
                                    <img src='/user-avatar.svg' />
                                    <p className='username'>{this.state.username.slice(0, 12)}</p>
                                </div>
                            </Link>
                            <div className='loguot-icon' onClick={this.logoutHundler}>
                                <img src='/logout-icon.svg' />
                            </div>
                        </div>
                    )}
                </div>
                <div className="select-lang">
                <a href="https://gamestatus.info/privacy-policy" target="_blank" id="hide_add_game" className="add-game"><span>PRIVACY POLICY</span></a>
                    <p className={this.props.userLang == 'ru' ? "selected-lang" : ""} onClick={() => setLangUrl('ru')}>RU</p>
                    <p className={this.props.userLang == 'en' ? "selected-lang" : ""} onClick={() => setLangUrl('en')}>EN</p>
                    <p className={this.props.userLang == 'zh' ? "selected-lang" : ""} onClick={() => setLangUrl('zh')}>中國</p>
                </div>

                {this.props.countTgUsers && showHeroBool ? <TelegramHeroBlock countTgUsers={this.props.countTgUsers}  /> : ''}
        </div>
        )
    }
}

function mapStateToProps(state) {
    const showHeroBool = state.showTgHero === undefined || state.showTgHero === true ? true : false
    return {
        search_result: state.search_result,
        auth_user: state.is_auth,
        userLang: state.userLang,
        countTgUsers: state.tgCountUsers,
    }
}

export default withRouter(connect(mapStateToProps)(TopBar));
