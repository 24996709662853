const initialState = {
    top_games: [],
    first_aaa_game: [],
    two_aaa_game: [],
    three_aaa_game: [],
    foo_aaa_game: [],
    game_block_one: [],
    game_block_two: [],
    game_block_three: [],
    game_block_foo: [],
    unreleased_game: [],
    search_result: [],
    is_auth: false,
    detail_game: {},
    comment_list: [],
    user_profile: {},
    game_calendar: [],
    game_cl_month: [],
    news_list: [],
    newsObj: {},
    latestCrackedGame: [],
    listCrackedGames: [],
    refGame: [],
    allRefGame: [],
    showRefGame: [],
    showWeakGame: [],
    userLang: 'ru',
    releasedGame: [],
    releasedGameSeasons: [],
    showReleasedGame: [],
    prevNews: [],
    gifList: [],
    youtubeVideo: [],
    listNewsGame: null,
    popularCrakedGames: [],
    tgCountUsers: 0,
    showTgHero: true,
    listRelatedNews: [],
}

function rootReducer(state=initialState, action) {
    switch(action.type) {
        case 'TOPGAMES':
            return {...state, top_games: action.value}
        case 'ONEAGAME':
            return {...state, first_aaa_game: action.value}
        case 'TWOAGAME':
            return {...state, two_aaa_game: action.value}
        case 'THREEAAGAME':
            return {...state, three_aaa_game: action.value}
        case 'FOOAGAME':
            return {...state, foo_aaa_game: action.value}
        case 'FIRSTGAMEBLOCK':
            return {...state, game_block_one: action.value}
        case 'SECONDGAMEBLOCK':
            return {...state, game_block_two: action.value}
        case 'THREEGAMEBLOCK':
            return {...state, game_block_three: action.value}
        case 'FOOGAMEBLOCK':
            return {...state, game_block_foo: action.value}
        case 'UNRELEAZEDGAME':
            return {...state, unreleased_game: action.value}
        case 'SEARCHRESULT':
            return {...state, search_result: action.value}
        case 'AUTHUSER':
            return {...state, is_auth: action.value}
        case 'DETAILGAME':
            return {...state, detail_game: action.value}
        case 'COMMENTGAME':
            return {...state, comment_list: action.value}
        case 'USERPROFILE':
            return {...state, user_profile: action.value}
        case 'GAMECALENDAR':
            return {...state, game_calendar: action.value}
        case 'GAMEMONTHCALENDAR':
            return {...state, game_cl_month: action.value}
        case 'NEWSLIST':
            return {...state, news_list: action.value}
        case 'NEWSOBJ':
            return {...state, newsObj: action.value}
        case 'LATESTCRACKEDGAME':
            return {...state, latestCrackedGame: action.value}
        case 'LISTCRACKEDGAMES':
            return {...state, listCrackedGames: action.value}
        case 'REFGAME':
            return {...state, refGame: action.value}
        case 'ALLREFGAME':
            return {...state, allRefGame: action.value}
        case 'SHOWREFGAME':
            return {...state, showRefGame: action.value}
        case 'SHOWWEAKGAME':
            return {...state, showWeakGame: action.value}
        case 'CHANGELANG':
            return {...state, userLang: action.value}
        case 'RELEASEDGAME':
            return {...state, releasedGame: action.value}
        case 'RELEASEDGAMESEASONS':
            return {...state, releasedGameSeasons: action.value}
        case 'SHOWRELEASEDGAME':
            return {...state, showReleasedGame: action.value}
        case 'PREVNEWSOBJ':
            return {...state, prevNews: action.value}
        case 'APPENDGIFLIST':
            return {...state, gifList: action.value}
        case 'YOUTUBEVIDEO':
            return {...state, youtubeVideo: action.value}
        case 'LISTNEWSGAME':
            return {...state, listNewsGame: action.value}
        case 'POPULARCRACKGAME':
            return {...state, popularCrakedGames: action.value}
        case 'TGCOUNTUSERS':
            return {...state, tgCountUsers: action.value}
        case 'LISTRELATEDNEWS':
            return {...state, listRelatedNews: action.value}
        case 'SHOWTGHERO':
            return {...state, showTgHero: action.value}
    }
    return state
}

export default rootReducer;