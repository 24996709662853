import Store from '../modules/store.js';

import { COOKIES } from '../constants.js';


export function returnRefGameImg(width) {
    const val = Math.floor(Math.random()*0);
    const fullImg = [
        ['/ref_img/wdl-ref.png', 'https://gamestatus.info/watch-dogs-legion'],
    ]
    return fullImg[val]
}
 
export function returnRefGameMainImg(width) {
    const listRefGame = [
        {
            'link': 'https://zaka-zaka.com/game/playerunknowns-battlegrounds/',
            'banner': 'https://f000.backblazeb2.com/file/GameStatus/promo_ref/promo-ref.jpg'
        },
    ];
    const val = Math.floor(Math.random()*0);
    return listRefGame[val]
}

export function setLangUrl(langToSet) {
    // const url = new URL(window.location.href);
    // url.searchParams.set('lg', langToSet);
    // window.history.replaceState(null, null, url);
    // const curLang = getLangFromCokies()
    // if (curLang != langToSet) {
    //     COOKIES.set('lg', langToSet, { path: '/' })
    Store.dispatch({type: 'CHANGELANG', value: langToSet})
    window.location.replace("/" + langToSet);
    //     document.location.reload();
    // }
}

export function getLangFromUrl() {
    const url_string = window.location.href
    let gameName = url_string.split('/')
    let langName = gameName[gameName.length -1]
    const supportLang = ["ru", "en", "zh",]
    if (supportLang.indexOf(langName) != -1) {
        return langName
    }

}

export function getLangFromCokies() {
    return COOKIES.get('lg', { path: '/' })
}

export function getLangAfterLoad() {
    // get lang from url
    const langFromUrl = getLangFromUrl()
    if (langFromUrl) {
        COOKIES.set('lg', langFromUrl, { path: '/' })
        Store.dispatch({type: 'CHANGELANG', value: langFromUrl})
        return langFromUrl
        // const supportLang = ['ru', 'uk', 'ua', 'by', 'kz', 'be', 'en',]
        // if (supportLang.indexOf(langFromUrl) != -1) {
        //     Store.dispatch({type: 'CHANGELANG', value: langFromUrl})
        //     return langFromUrl
        // } else {
        //     Store.dispatch({type: 'CHANGELANG', value: 'en'})
        //     return 'en'
        // }
    } else {
        let lang = getLangFromCokies()
        if (!lang) {
            Store.dispatch({type: 'CHANGELANG', value: 'ru'})
            return 'ru'
        } else {
            Store.dispatch({type: 'CHANGELANG', value: lang})
            return lang
        }
    }
    // get lang from COOKIES
    // let lang = getLangFromCokies()
    // if (!lang || lang == 'ru') {
    //     Store.dispatch({type: 'CHANGELANG', value: 'ru'})
    //     return 'ru'
    // } else if (lang == 'en') {
    //     setLangUrl(lang)
    //     Store.dispatch({type: 'CHANGELANG', value: 'en'})
    //     return 'en'
    // } else {
    //     setLangUrl(lang)
    //     Store.dispatch({type: 'CHANGELANG', value: 'en'})
    //     return 'en'
    // }
}

export function newsWrapperListItmName(string, maxLenght=65) {
    if (string.length > maxLenght) {
        let croppedString = string.substring(0, maxLenght)
        let list_word = croppedString.split(' ')
        let splittedString = list_word.slice(0, -1).join(' ')
        if (splittedString.endsWith(',')) {
            let lengthString = splittedString.length
            return splittedString.substring(0, lengthString -1)
        }
        return splittedString += ' ...'
    }
    return string
}

export function getAnotherLangPage(lang) {
    if (lang.includes('ru')) {
        var locale = 'en'
    } else {
        var locale = 'ru'
    }
    return locale
}
