import React, { Component } from 'react';
import { connect } from 'react-redux';

import { COOKIES, HOST } from '../../constants.js';
import './oauthlogin.css';

class OauthLoginPage extends Component {

    constructor(props) {
        super(props)
        this.userLocale = this.props.userLang
    }

    componentDidMount() {
        let path = window.location.href
        let token = path.split('?token=')[1].replace('#_=_', '')
        COOKIES.set('jwt_token', token, { path: '/', maxAge: 365*24*60*60 })
        this.props.history.push('/' + this.userLocale)

        let jwtToken = COOKIES.get('jwt_token');
        let isActiveAds = COOKIES.get('is_active_ads');
        if (jwtToken && parseInt(isActiveAds) == 0) {
            fetch(HOST + 'auth/user/disable_ads/', {
                method: "POST",
                credentials: 'omit',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'JWT ' + jwtToken
                }
            })
        }
        if (jwtToken && parseInt(isActiveAds) != 0) {
            fetch(HOST + 'auth/user/', {
                credentials: 'omit',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'JWT ' + jwtToken,
                }
            })
                .then(response => response.json())
                .then(json => {
                    let userName = json['username']
                    COOKIES.set('username', userName, { path: '/' })
                    this.setState({username: userName})
                    COOKIES.set('is_active_ads', json['is_active_ads'], { path: '/' })
                })
        }
        this.setState({load: false})
    }

    render() {
        return (<span className='redirect-text'>redirect</span>)
    }
}
function mapStateToProps(state) {
    return {
        userLang: state.userLang,
    }
}

export default connect(mapStateToProps)(OauthLoginPage);