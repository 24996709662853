import React, { Component } from 'react';

import { connect } from 'react-redux';
import YouTube from 'react-youtube';

import './youtube_news.css';
import { COOKIES, STRINGLOCAL } from '../../constants.js';


class YoutubeNewsGame extends Component {
    constructor(props) {
        super(props)
        this.state = {video_id: null, ready: false}
        this.userLocale = this.props.userLang
    }

    pauseFunc = () => {
        // COOKIES.set('video_id', this.state.video_id, { path: '/' })
        COOKIES.set('stop_auto_play', '1', { path: '/' })
        this.forceUpdate()
    }

    _onReady = (event) => {
        return  // never from 17.12
        // this.setState({ready: true})
        // let stopAutoPlay = COOKIES.get('stop_auto_play')
        // if (stopAutoPlay == '1') {
        //     return
        // }
        // else if (window.innerWidth < 1170) {
        //     return
        // } else {
        //     event.target.setVolume(35)
        //     event.target.playVideo()
        // }
        // let video_id = COOKIES.get('video_id')
        // if (!video_id || video_id != this.state.video_id) {
        //     event.target.setVolume(35)
        //     event.target.playVideo()
        // }
      }

      componentDidMount() {
        if (this.userLocale.includes('ru')) {
            let _video_id = this.props.youtubeVideo[0]['video_id']
            this.setState({video_id: _video_id})
        } else {
            let _video_id = this.props.youtubeVideo[1]['video_id']
            this.setState({video_id: _video_id})
        }
    }

    // checkAutoPlayStatus = () => {
    //     let stopAutoPlay = COOKIES.get('stop_auto_play')
    //     if (stopAutoPlay == '0') {
    //         return <p className="stop-view-text" onClick={() => this.stopAutoPlay()}>{STRINGLOCAL.stop_auto_play}</p> 
    //     } else if (stopAutoPlay == '1') {
    //         return <p className="stop-view-text" onClick={() => this.resumeAutoPlay()}>{STRINGLOCAL.resume_auto_play}</p> 
    //     } else {
    //         // if missing value, first open
    //         return <p className="stop-view-text" onClick={() => this.stopAutoPlay()}>{STRINGLOCAL.stop_auto_play}</p> 
    //     }
    // }

    // stopAutoPlay = () => {
    //     COOKIES.set('stop_auto_play', '1', { path: '/' })
    //     this.forceUpdate()
    // }

    // resumeAutoPlay = () => {
    //     COOKIES.set('stop_auto_play', '0', { path: '/' })
    //     this.forceUpdate()
    // }


    render() {
        const opts = {
            height: '255',
            width: '435',
            playerVars: {
              autoplay: 0,
            },
          }
        if (this.state.video_id) {
            return (
                <div className="youtube-news-main-block">
                    <YouTube videoId={this.state.video_id} className="youtube-news" opts={opts} onReady={this._onReady} onPause={this.pauseFunc} onEnd={this.pauseFunc} />
                    {/* {this.state.ready ? this.checkAutoPlayStatus() : ''} */}
                </div>
            )
        } return ''
    }
}

function mapStateToProps(state) {
    return {
        youtubeVideo: state.youtubeVideo,
        userLang: state.userLang,
    }
}

export default connect(mapStateToProps)(YoutubeNewsGame);