import React, { Component } from 'react';

import { Adsense } from '@ctrl/react-adsense';

import { COOKIES } from '../../constants.js';

class MiddleAdsShort extends Component {

    render() {
        let isActiveAds = COOKIES.get('is_active_ads');
       
        if (parseInt(isActiveAds) != 0) {
            return <Adsense
                client="ca-pub-6277942134397670"
                slot="8109699047"
                format="fluid"
                layoutKey="-ef+6m-2i-c0+uy"
                style={{ display: "block"}}
            />
        } else {
            return ""
        }
    }
}

export default MiddleAdsShort;