import React, { Component } from 'react';

import YouTube from 'react-youtube';
import Store from '../../modules/store.js';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { 
    FacebookShareButton, 
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    VKShareButton,
    VKIcon
} from "react-share";
import { withRouter } from "react-router";
import { Adsense } from '@ctrl/react-adsense';

import './news-detail.css';
import TopBar from '../top-bar/top-bar.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import MiddleAds from '../middle-ads/middle-ads.js';
import MiddleAdsShort from '../middle-ads/middle-ads-chort.js';
import { HOST, REFLINK, STRINGLOCAL } from '../../constants';
import Loader from '../loader/loader.js';
import { returnRefGameMainImg, newsWrapperListItmName } from '../utils';

class NewsDetail extends Component {

    constructor(props) {
        super(props)
        this.state = {loaded: false, notFound: false, showPopUp: false}
        this.refGameGame = returnRefGameMainImg();
        this.firstClick = false
        this.userLocale = this.props.userLang
    }

    componentDidMount() {

        this.getNewsDeatil()
        this.showPopUp()
    }

    showPopUp = () => {
        // if (!this.firstClick) {
        //     let firstShow = parseInt(COOKIES.get('firstShow')) || 0
        //     let countShow = parseInt(COOKIES.get('countShow')) || 0
        //     if (!firstShow && countShow == 3) {
        //         COOKIES.remove('firstShow')
        //         COOKIES.remove('countShow')
        //         COOKIES.set('firstShow', 1)
        //         COOKIES.set('countShow', countShow +=1)
        //         this.setState({showPopUp: true})
        //     } else if (countShow >= 8) {
        //         COOKIES.remove('countShow')
        //         COOKIES.set('countShow', 0)
        //         this.setState({showPopUp: true})
        //     } 
        //     else {
        //         COOKIES.remove('countShow')
        //         COOKIES.set('countShow', countShow +=1)
        //     }
        //     this.firstClick = true
        // }
    }

    getNewsDeatil =() => {
        let path = window.location.href
        path = path.split("/")
        let newsSlug = path[4]
        // var gameName = path.split('news-detail?news=')[1]
        // if (!gameName) {
        //     gameName = path.split('_newsdetail-')[1]
        // } 
        // if (!gameName) {
        //     gameName = path.split('newsdetail-')[1]
        // }
        const params = { headers: {
            'Content-Type': 'application/json',
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,
        }}
        fetch(`${HOST}news/${newsSlug}`, params)
            .then((response) => {
                if (!response.ok) {
                    this.setState({notFound: true, loaded: true})
                } else {
                    return response.json()
                }
            })
            .then(json => {
                Store.dispatch({type: 'LATESTCRACKEDGAME', value: json.game_list})
                Store.dispatch({type: 'NEWSOBJ', value: json.news})
                Store.dispatch({type: 'PREVNEWSOBJ', value: json.prev_news})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.count_tg_users})
                this.setState({loaded: true})
            })
            .catch(error => {
                return Promise.reject()
            })
    }

    renderVideoNews = (props) => {
        if (props.newsDetail.source == 'youtube') {
            return (
                <div className='video-wrapper-new-youtube'>
                    <YouTube videoId={this.props.newsDetail.body_video_id} className='video-wrapper-youtube-news' />
                    <div className="news-wrapper-list">
                        {props.prevNews.map((obj, i) => {
                            return (
                                <div className="news-wrapper-list-itm" key={i}><a href={`/newsdetail/` + obj.slug + "/" + this.userLocale} target="_blank"><p>{newsWrapperListItmName(obj.title, 95)}</p></a></div>
                            )
                        })}
                    </div>
                </div>
            )
        }
    }

    render() {
        //return loader
        if (!this.state.loaded) {
            return (
                <div className='detail-game-main'>
                    <Loader />
                </div>
            )
        }
        //return 404 page
        if (this.state.notFound) {
            return (
                <div className='error-code-wrapper'>
                    <p className='error-code'>404</p>
                </div>
            )
        }

        return (
            <div className="news-wrapper news-wrapper-youtube">
                {this.state.showPopUp ? <a href={REFLINK} target="_blank" className="ref-absolute-block " onClick={() => this.setState({showPopUp: false})}></a> : ''}
                {/* <LeftSideAds />
                <RightSideAds /> */}
                <Helmet>
                    <title>{this.props.newsDetail.meta_title}</title>
                    {this.props.newsDetail.source_site == 'original' ? <link rel="canonical" href={`https://gamestatus.info/newsdetail/${this.props.newsDetail.slug}/${this.userLocale}`} data-react-helmet="true" /> : <link rel="canonical" href='https://gamestatus.info/about-us' data-react-helmet="true" />}
                    <meta property="og:description" content={this.props.newsDetail.meta_description} />
                    <meta name="description" content={this.props.meta_description} />
                    <meta property="og:title" content={this.props.newsDetail.meta_title} data-react-helmet='true' />
                    <meta property="og:image" content={this.props.newsDetail.preview} data-react-helmet='true' />
                    <meta property="og:image:secure_url" content={this.props.newsDetail.preview} data-react-helmet='true' />
                    <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                </Helmet>
                <div className='news-main'>
                    <TopBar countTgUsers={this.props.countTgUsers} />
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    {/* <MiddleAds /> */}
                    {this.props.newsDetail.source != 'youtube' ? 
                        <div className="news-container">
                            <div className="news-preview">
                                <img src={this.props.newsDetail.preview} alt={this.props.newsDetail.title} />
                                <h1 className="news-title-detail">{this.props.newsDetail.title}</h1>
                            </div>
                            <FacebookShareButton 
                                    url={`https://gamestatus.info/news-detail?news=${this.props.newsDetail.slug}`}
                                    quote={this.props.newsDetail.meta_title}
                                >
                                <FacebookIcon size={40} />
                            </FacebookShareButton>
                            <VKShareButton 
                                    url={`https://gamestatus.info/news-detail?news=${this.props.newsDetail.slug}`}
                                    title={this.props.newsDetail.meta_title}
                                    image={this.props.newsDetail.preview}
                                >
                                <VKIcon size={40} />
                            </VKShareButton>
                            <TelegramShareButton 
                                    url={`https://gamestatus.info/news-detail?news=${this.props.newsDetail.slug}`}
                                    title={this.props.newsDetail.meta_title}
                                >
                                <TelegramIcon size={40} />
                            </TelegramShareButton>
                            <div className="news-body">
                                <p dangerouslySetInnerHTML={{__html: this.props.newsDetail.body}} />
                            </div>
                        </div>
                    : '' }
                    {this.renderVideoNews(this.props)}

                    {window.innerWidth <= 700 ? 
                        <MiddleAdsShort /> : ''
                    }
                    {/* {this.userLocale == 'ru' ? 
                        <div className="last-crack-game">
                            <h5 className="unreleased-game-title">Другие новости</h5>
                            {this.props.prevNews.map((objPrevNews, i) => (
                                <div className="news-wrapper" key={i}>
                                    <div className="news-list news-list-detail">
                                        <a href={'/newsdetail-' + objPrevNews.slug}>
                                            <div className="news-block">
                                                <img className="news-image" src={objPrevNews.preview} alt={objPrevNews.title} />
                                                <p className="news-list-title">{objPrevNews.title}</p>
                                                <h6 className="short-desc" dangerouslySetInnerHTML={{__html: objPrevNews.body}} />
                                            </div>
                                        </a>    
                                    </div>
                                </div>
                            ))}
                        </div>
                    : '' } */}
                    <div className="last-crack-game">
                        <h5 className="unreleased-game-title">{STRINGLOCAL.recentlyCrackedGames}</h5>
                        <div className="cracked-game-list">
                            {this.props.latestCrackedGame.map((obj, i) => {
                                return (
                                    <div className="small-game-cont" key={i}>
                                        <div className="image-wrapper">
                                            <a href={'/' + obj.slug + "/" + this.userLocale}>
                                                <img src={obj.short_image} alt={obj.title} />
                                            </a>
                                            <h2 className="game-name">{obj.title.slice(0, 28)}</h2>
                                            <h3 className="label-game-status game-cracked-home game-cracked-yes-home">{obj.readable_status}</h3>
                                            <div className="release-date release-date-small">
                                                <img src="/calendar-1.svg" />
                                                <span>{obj.release_date.slice(0, 10)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <BottomInfo />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        newsDetail: state.newsObj,
        latestCrackedGame: state.latestCrackedGame,
        prevNews: state.prevNews,
        userLang: state.userLang,
        countTgUsers: state.tgCountUsers,
    }
}

export default withRouter(connect(mapStateToProps)(NewsDetail));