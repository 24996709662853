import React, { Component } from 'react';

import { HOST, COOKIES, STRINGLOCAL } from '../../constants.js';
import { connect } from 'react-redux';


class TelegramHeroBlock extends Component {

    render() {
        return(
            <a target="_blank" href="https://t.me/gamestatusinfo">
                <div className="news-home-prewiw tg-hero-bl hero-bl">
                    <div className="hero-bl-wrapper tg-block-info-l">
                        <div className="hero-title-tg">
                            <a href="https://www.digiseller.market/asp2/pay_wm.asp?id_d=4244514&lang=ru-RU">
                                <p>{STRINGLOCAL.tgHero}</p>
                            </a>
                            <img src="/money1.png" />
                        </div>
                        <div className="tg-hero-devider"></div>
                        <div className="tg-block-info-r">
                            <img src="/logo_4x4.png" />
                            <div className="chanel-title">
                                <p>GameStatusInfo</p>
                                <p>{this.props.countTgUsers + STRINGLOCAL.usersTG}</p>
                                <div className='tg-chanel-btn'>
                                    <p>View in Telegram</p>
                                    {/* <p>$ SUPPORT US</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </a>
        )
    }
}

function mapStateToProps(state) {
    return {
        countTgUsers: state.tgCountUsers,
        showTgHero: state.showTgHero,
    }
}

export default connect(mapStateToProps)(TelegramHeroBlock);