import React, { Component } from 'react';

import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { Adsense } from '@ctrl/react-adsense';

import TopBar from '../top-bar/top-bar.js';
import { HOST, STEAMPAYREFERRAL, COOKIES } from '../../constants.js';
import Loader from '../loader/loader.js';
import Store from '../../modules/store.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { returnRefGameImg } from '../utils';
import './weak-game-list.css';



class PopularGameLiteGame extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false,
            loadMoreGame: false
        }
        this.divRef = React.createRef();
        this.maxWidth = window.innerWidth;
        this.imgRefGame = returnRefGameImg(this.maxWidth);
    }

    componentDidMount() {
        this.getGameWeakList()
        window.addEventListener('scroll', this.scrollHundler);
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.scrollHundler);
    }

    getGameWeakList() {
        fetch(HOST + 'gameinfo/game/referralsgame/')
            .then(response => response.json())
            .then(json => {
                let listWeakGame = []
                for (let i = 0; i < json.length; i++) {
                    var obj = json[i]
                    if (obj.mata_score > 70) {
                        obj.metacriticCss = 'game-rate-weak game-cracked'
                    } else if (obj.mata_score > 40) {
                        obj.metacriticCss = 'game-rate-weak game-middle'
                    } else if (obj.mata_score > 0) {
                        obj.metacriticCss = 'game-rate-weak game-uncracked'
                    }
                    listWeakGame.push(obj);
                }
                Store.dispatch({type: 'SHOWWEAKGAME', value: listWeakGame})
                this.setState({loaded: true, selectOneMonth: true})
            })
    }

    scrollHundler = () => {
        if (this.divRef.current) {
            let scrollY = window.pageYOffset
            let heigghtDiv = this.divRef.current.offsetHeight
            //search for percent of the number
            let calc = scrollY / heigghtDiv * 100
            if (calc >= 12  && !this.state.loadMoreGame) {
                this.setState({loadMoreGame: true})
            }
        }
    }

    render() {
        let countGame = 0
        let showRefGame = true
        if (!this.state.loaded) {
            return (
                <div className='main-wrapper'>
                    <div className="home-main-container">
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <div className='main-wrapper'>
                <Helmet>
                    <title>ТОП 200 Крутых Игр На Слабые ПК | Game Status</title>
                    <link rel='canonical' href='https://gamestatus.info/popular-games-weak-pc' data-react-helmet='true' />
                    <meta property="og:description" content="Выбери крутые игры для своего слабого ПК (PC) или Ноутбука. Топ игры для слабых компьютеров отсортированы по рейтингу и содержат технические характеристики" data-react-helmet='true' />
                    <meta name="description" content="Выбери крутые игры для своего старого ПК (PC) или Ноутбука. Топ игры для слабых компьютеров отсортированы по рейтингу и содержат технические характеристики" />
                    <meta property="og:image" content="https://gamestat.s3.eu-west-2.amazonaws.com/logo_preview.png" data-react-helmet='true' />
                    <meta property="og:title" content="ТОП 200 Крутых Игр На Слабые ПК" data-react-helmet='true' />
                    <meta property="og:site_name" content="GameStatus - статус взлома новых игр с защитой Denuvo" data-react-helmet='true' />
                    <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                </Helmet>
                <div className="home-main-container">
                    <TopBar hideCalendar={true} />
                    <div className="distance"></div>
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    <h3 className="text-hot-game small-text">
                        Здесь вы найдете игры для своего старого ПК или ноутбука. Игры отсортированы по рейтингу и доступны для скачивания через торрент.
                    </h3>
                    <h1 className="calendar-title">ТОП 200 лучших игр для слабых пк</h1>
                    <div className="calendar-wrapper">
                        <div className="calendar-game-wrapper cr-game-wrapper">
                            <div className="describe-color">
                                <div className="blue-calendar"></div>
                                <span>Обычная Игра</span>
                                <div className="red-calendar"></div>
                                <span>ААА игра</span>
                            </div>
                            <div className="game-in-calendar" ref={this.divRef}>
                                {this.props.showWeakGame[0].map((obj, i) => {
                                    countGame += 1
                                    if (!this.state.loadMoreGame && countGame >= 20) {
                                        return
                                    } else if (obj.is_AAA) {
                                        return (
                                            <Link to={'/' + obj.slug} key={i}>
                                                <div className="game-cl-us game-cl-aaa">
                                                    <img className="calenad-img-game" src={obj.short_image} alt={`Взлом ${obj.title}, технические характеристики`} />
                                                    <div className='system-info-block tech-label'>
                                                        {obj.specs_info.cpu_info && obj.specs_info.cpu_info.toLowerCase() != "info" && obj.specs_info.cpu_info.toLowerCase() != "tdb" ?
                                                            <div className='system-info-block-param popular-gam'>
                                                                <p className='game-info-key'>CPU:</p>
                                                                <span className='game-info-key game-info-specs'>{obj.specs_info.cpu_info}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {obj.specs_info.ram_info && obj.specs_info.ram_info.toLowerCase() != "info" && obj.specs_info.ram_info.toLowerCase() != "tdb" ?
                                                            <div className='system-info-block-param popular-gam'>
                                                                <p className='game-info-key'>RAM:</p>
                                                                <span className='game-info-key game-info-specs'>{obj.specs_info.ram_info}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {obj.specs_info.os_info && obj.specs_info.os_info.toLowerCase() != "info" && obj.specs_info.os_info.toLowerCase() != "tdb" ?
                                                            <div className='system-info-block-param popular-gam'>
                                                                <p className='game-info-key'>OC:</p>
                                                                <span className='game-info-key game-info-specs'>{obj.specs_info.os_info}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                        {obj.specs_info.gpu_info && obj.specs_info.gpu_info.toLowerCase() != "info" && obj.specs_info.gpu_info.toLowerCase() != "tdb" ?
                                                            <div className='system-info-block-param popular-gam'>
                                                                <p className='game-info-key'>GPU:</p>
                                                                <span className='game-info-key game-info-specs'>{obj.specs_info.gpu_info}</span>
                                                            </div>
                                                            : ''
                                                        }
                                                    </div>
                                                    <div className="game-cl-rl-date game-cr-game-date game-weak-game-date">
                                                        <img src="/calendar-1.svg" />
                                                        <p>{obj.release_date}</p>
                                                    </div>
                                                    <p className="rate-game-position"><span className="meta-critic-label">Score: </span><span className={obj.metacriticCss}>{obj.mata_score}</span></p>
                                                </div>
                                            </Link>
                                        )
                                    } else {
                                        return (
                                            <Link to={'/' + obj.slug} key={i}>
                                                <div className="game-cl-us">
                                                    <p className="cl-title-game">{obj.title}</p>
                                                    <div className="game-cl-rl-date game-cr-game-date">
                                                        <img src="/calendar-1.svg" />
                                                        <p>{obj.release_date}</p>
                                                    </div>
                                                    <p className="rate-game-position"><span className="meta-critic-label">Score: </span><span className={obj.metacriticCss}>{obj.mata_score}</span></p>
                                                </div>
                                            </Link>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <BottomInfo />
                </div>
            </div>
        )
    }
}


function mapStateToProps(state) {
    return {
        showWeakGame: [state.showWeakGame],
    }
}

export default connect(mapStateToProps)(PopularGameLiteGame);