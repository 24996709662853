import React, { Component } from 'react';

import { Link } from 'react-router-dom'

import '../loginpage/login-page.css'
import '../registerpage/register-page.css'
import { HOST, COOKIES } from '../../constants.js'
import Loader from '../loader/loader.js'


class ChangePassword extends Component {

    constructor(props) {
        super(props)
        this.state = {errLoginMess: '',  errCurrentPass: false, 
                      errPassOneInput: false, errPassTwoInput: false,
                      registerComplete: false, load: false
                    }
        this.passOneInput = React.createRef();
        this.passTwoInput = React.createRef();
        this.currentPass = React.createRef();
        this.jwt = COOKIES.get('jwt_token')
    }

    componentDidMount() {
        if (!this.jwt) {
            this.props.history.push('/sign-in')
        }
        document.title = 'Изменение пароля | Gamestatus'
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.registerHundler()
        }
    }

    registerHundler = () => {
        let passOneValue = this.passOneInput.current.value
        let passTwoValue = this.passTwoInput.current.value
        let currentPass = this.currentPass.current.value

        if (currentPass.length) {
            this.setState({'errCurrentPass': false})
        } else {
            this.setState({'errCurrentPass': true})
        }

        if (passOneValue.length) {
            this.setState({'errPassOneInput': false})
        } else {
            this.setState({'errPassOneInput': true})
        }

        if (passTwoValue.length) {
            this.setState({'errPassTwoInput': false})
        } else {
            this.setState({'errPassTwoInput': true})
        }

        if (passOneValue != passTwoValue) {
            this.setState({
                errLoginMess: 'Пароли должны совпадать',
                errPassOneInput: true, errPassTwoInput: true,
                load: false
            })
            return
        }

        if (passTwoValue.length < 6) {
            if (passTwoValue.length > 0) {
                this.setState({
                    errLoginMess: 'Пароль cлижком легкий',
                })
            }
            return
        }

        this.setState({load: true})
        let data = {
            "current_pass": currentPass,
            "new_password": passTwoValue
        }

        fetch(HOST + 'auth/user/change_password/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + this.jwt,
            }})
            .then((response) => {
                if (!response.ok) {
                    response.json().then((json) => {
                        this.setState({'errCurrentPass': true, errLoginMess: json.err, load: false})
                    })
                } else {
                    return response.json()
                }
            })
            .then(json => {
                COOKIES.remove('jwt_token', { path: '/' })
                COOKIES.set('jwt_token', json.jwt_token, { path: '/' })
                this.setState({registerComplete: true})
            })
            .catch(error => {
                return Promise.reject()
            })
    }

    render() {
        if (this.state.registerComplete) {
            return (
                <div className='main'>
                    <div className='content'>
                        <div className='sign-in-wellcome'>
                            <img className='sign-in-logo' src='/logo.svg' />
                            <p className='sign-in-title'>GAMESTATUS</p>
                        </div>
                        <div className='sign-in-block register-block register-complete'>
                            <p className='sign-in-white-text'>Ваш пароль был успешно изменен</p>
                            <Link to='/profile'><span className='sign-in-green-text'> Назад в профиль</span></Link>
                        </div>
                    </div>
                </div>
            )
        }
        return (
            <div className='main' onKeyDown={this.handleKeyDown}>
                <div className='content'>
                    <Link to='/'>
                        <div className='sign-in-wellcome'>
                            <img className='sign-in-logo' src='/logo.svg' />
                            <p className='sign-in-title'>GAMESTAT</p>
                        </div>
                    </Link>
                    <div className='sign-in-block register-block'>
                        <p className='sign-in-text'>Изминение пароля</p>
                        <input className={this.state.errCurrentPass? 'input-field red-borad' : 'input-field'} 
                            type='password' placeholder='Текущий пароль' ref={this.currentPass} />
                        <input className={this.state.errPassOneInput ? 'input-field red-borad' : 'input-field'} 
                            type='password' placeholder='Новый пароль' ref={this.passOneInput} />
                        <input className={this.state.errPassTwoInput ? 'input-field red-borad' : 'input-field'} 
                            type='password' placeholder='Подтвердить пароль' ref={this.passTwoInput} />
                        <p className='err-text'>{this.state.errLoginMess}</p>
                        {this.state.load ? <Loader /> : <p className='sign-in-button' onClick={this.registerHundler}>Изменить пароль</p>}
                        <Link to='/profile'>
                            <p className='sign-in-white-text sign-in-green-text'>Назад в профиль</p>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ChangePassword;