import React, { Component } from 'react';

import { connect } from 'react-redux';
import CountUp from 'react-countup';
import { Adsense } from '@ctrl/react-adsense';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import YouTube from 'react-youtube';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartPlus, faSmileBeam, faSearch, faTimesCircle} from '@fortawesome/free-solid-svg-icons';

import './purchase-game.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TopBar from '../top-bar/top-bar.js';
import { HOST, STEAMPAYREFERRAL, COOKIES, REFLINK, STRINGLOCAL } from '../../constants.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import { returnRefGameImg } from '../utils';
import Store from '../../modules/store.js';
import Loader from '../loader/loader.js';
import { getAnotherLangPage } from '../utils';


class PurchaseGame extends Component {
    constructor(props) {
        super(props)
        this.state = {
            notFound: false, loaded: false,
            loadedImg: false,
        }
        this.userLocale = this.props.userLang
        this.settingsSlick = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 3000,
            centerMode: false,
            arrows: false
        }
    }

    componentDidMount() {
        this.getGameInfo()
    }

    getGameInfo = () => {
        let path = window.location.href
        let gameName = path.split('purchasegame-')
        gameName = gameName[gameName.length -1]
        let params = {headers: {
            'Content-Type': 'application/json',
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,
        }}

        fetch(HOST + 'gameinfo/game/'+ gameName + '/', params)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusCode);
                } else {
                    return response.json()
                }}
            )
            .then(json => {
                Store.dispatch({type: 'DETAILGAME', value: json})
                Store.dispatch({type: 'YOUTUBEVIDEO', value: json.youtube_video_id})
                this.setState({loaded: true, isSubscribe: json.is_subscribe})
            })
    }

    getDescPurchaseGameOg = () => {
        if (this.userLocale.includes('ru')) {
            return this.props.detail_game.referrals_game[0].add_info
        } else {
            return this.props.detail_game.referrals_game[0].add_info_en
        }
    }

    renderAditionalInfo = (add_info) => {
        if (add_info) {
            return (<div>
                <p className='system-req-text'>{STRINGLOCAL.additional}</p>
                <h3 className="game-info-key" dangerouslySetInnerHTML={{__html: add_info}} />
            </div>)
        }
    }


    render() {
        if (!this.state.loaded) {
            return (
                <div className='detail-game-main'>
                    <Loader />
                </div>
            )
        }
        return (
            <div className='detail-game-wrapper detail-game-wrapper-ref'>
                <LeftSideAds />
                <RightSideAds />
                <Helmet>
                    <title>{STRINGLOCAL.purchaseOfflineAct} {this.props.detail_game.title}</title>
                    <meta property="og:title" content={`${STRINGLOCAL.purchaseOfflineAct}  ${this.props.detail_game.title}`} data-react-helmet='true' />
                    <meta property="og:image" content={this.props.detail_game.full_image} data-react-helmet='true' />
                    <meta property="og:image:secure_url" content={this.props.detail_game.full_image} data-react-helmet='true' />
                    <link rel="alternate" hreflang={getAnotherLangPage(this.userLocale)} href={`https://gamestatus.info/purchasegame-${this.props.detail_game.slug}/${getAnotherLangPage(this.userLocale)}`} />
                    <link rel='canonical' href={`https://gamestatus.info/purchasegame-${this.props.detail_game.slug}?lg=${this.userLocale}`} data-react-helmet='true' />
                    <meta
                        name="description"
                        content={this.getDescPurchaseGameOg()}
                    />
                    <meta 
                        property="og:description" 
                        content={this.getDescPurchaseGameOg()}
                        data-react-helmet='true'
                    />-
                    <meta property="og:site_name" content={`Gamestatus - ${STRINGLOCAL.purchaseOfflineAct} ${this.props.detail_game.title}`} data-react-helmet='true' />
                </Helmet>
                <div className='detail-game-main cracked-games main-background'>
                    <TopBar />
                    <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div>

                    <div className='title-navigator'>
                        <Link to='/'><p>{STRINGLOCAL.home_page}</p></Link>
                        <p> {'>'} </p>
                        <h1>{STRINGLOCAL.offline_act} {this.props.detail_game.title}</h1>
                    </div>
                    <div className='title-navigator title-navigator-min'>
                        <h1><span className="crack-game-text">{STRINGLOCAL.offline_act} </span>{this.props.detail_game.title}</h1>
                    </div>

                    <div class="info-game-block info-game-block-ref">
                        {window.innerWidth <= 1035 ?
                            <img className={this.state.loadedImg ? 'img-loaded' : 'img-load'} onLoad={() => {this.setState({loadedImg: true})}} src={this.props.detail_game.short_image} alt={`Взлом ${this.props.detail_game.title}, дата выхода`} /> : 
                            <img className={this.state.loadedImg ? 'img-loaded' : 'img-load'} onLoad={() => {this.setState({loadedImg: true})}} src={this.props.detail_game.short_image} alt={`Взлом ${this.props.detail_game.title}, дата выхода`} />
                        }
                        <div className='purchase-price'>
                            <a href={`https://www.oplata.info/asp2/pay_enot.asp?id_d=${this.props.detail_game.referrals_game[0].id_goods}&ai=972665`} target="_blank">
                                <p><span>{<FontAwesomeIcon className="ref-title-game color-bucket" icon={faCartPlus} />}</span><span class="purchace-title">{STRINGLOCAL.purchace}</span></p>
                                <p>{this.props.detail_game.referrals_game[0].price} ₽</p>
                            </a>
                        </div>
                         {/* <div className="slick-cont-wrapper slick-cont-wrapper-ref show-element">
                            <Slider {...this.settingsSlick}>
                                <div>
                                    <img src={this.props.detail_game.short_image} />
                                </div>
                                <div>
                                    <img src={this.props.detail_game.first_referal_img} />
                                </div>
                            </Slider>
                        </div> */}
                        {/* <div className='info-game-wrapper'>
                            <div className='title-info-top'>{
                                this.userLocale.includes('ru') ? this.props.detail_game.description : this.props.detail_game.description_en
                            }</div>
                        </div> */}
                        <div className='slider-ref-game'>
                            <Slider {...this.settingsSlick}>
                                {this.props.detail_game.list_ref_images.map((itm, i) => {
                                return <img src={itm} key={i} />
                                })}
                            </Slider>
                        </div>
                        
                    </div>
                    {/* {this.props.detail_game.teaser_link ? (
                        <div className='video-container'>
                            <YouTube videoId={this.props.detail_game.teaser_link.split('watch?v=')[1]} className='video-wrapper-ref' />
                        </div>) : ''
                    } */}
                    {this.props.detail_game.teaser_link ? (
                        <div className='video-bl-purchase'>
                            <p className='system-req-text'>{STRINGLOCAL.trailer}</p>
                            <div className='video-container'>
                                <YouTube videoId={this.props.detail_game.teaser_link.split('watch?v=')[1]} className='video-wrapper-ref' />
                            </div>
                        </div>) : ''
                    }
                    <div className="desc-detail-game desc-detail-game-ref">
                        <p className='system-req-text'>{STRINGLOCAL.descPurchaseGame}</p>
                        {this.userLocale.includes('ru') ? 
                        <div>
                            <h3 className="game-info-key" dangerouslySetInnerHTML={{__html:this.props.detail_game.referrals_game[0].info_goods}} />
                            {this.renderAditionalInfo(this.props.detail_game.referrals_game[0].add_info)}
                        </div> : 
                        <div>
                            <h3 className="game-info-key" dangerouslySetInnerHTML={{__html:this.props.detail_game.referrals_game[0].info_goods_en}} />
                            {this.renderAditionalInfo(this.props.detail_game.referrals_game[0].add_info_en)}
                        </div>
                        }
                    </div>
                </div>
                <BottomInfo />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang,
        detail_game: state.detail_game,
    }
}

export default connect(mapStateToProps)(PurchaseGame);