import React, { Component } from 'react';

import './remove-ads.css'
import { STRINGLOCAL } from '../../constants.js';


class NoAdsPopUp extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <a href="https://gamestatus.info/disable-ads" className="background-not-ads dbox-donation-button">
                <div className='background-not-ads-wrap'>
                    <img src="/no-ads-circle.png" />
                    <div className='background-not-ads-body'>
                        <p>{STRINGLOCAL.removeAds} & <span>Support us</span></p>
                        <div class="wrap">
                            <button class="button">CLICK</button>
                        </div>
                    </div>
                </div>
            </a>
        )
    }

}
export default NoAdsPopUp;