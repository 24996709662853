import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

import './homepage.css'
import TopBar from '../top-bar/top-bar.js';
import { HOST, STEAMPAYREFERRAL, COOKIES, REFLINK, STRINGLOCAL } from '../../constants.js';
import Store from '../../modules/store.js';
import Loader from '../loader/loader.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import MiddleAds from '../middle-ads/middle-ads.js';
import MiddleAdsShort from '../middle-ads/middle-ads-chort.js';
import { Link } from 'react-router-dom';
import { returnRefGameImg, returnRefGameMainImg, customDetectLang, getAnotherLangPage } from '../utils';
import TelegramHeroBlock from '../top-bar/telegram_hero.js';
import OfflineActBanner from '../top-bar/banner-offline-act.js';



class HomePage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            load: false, blockOne: false, blockTwo: false, blockThree: false,
            blockFoo: false, blockFive: false, blockSix: false, blockSeven: false,
            blockEight: false, blockNine: false, blockTen: false, blockEleven: false,
            loadUnreleasedGame: false, blockTwelve: false, blockThirteen: false,
            blockFourteen: false, blockFifteen: false, blockSixteen: false,
            blockSeventeen: false, blockEighteen: false, blockNineteen: false,
            loadSecondBlockPopGames: false,
        };
        this.oldUrl = ''
        this.divRef = React.createRef()
        this.maxWidth = window.innerWidth;
        this.imgRefGame = returnRefGameImg(this.maxWidth);
        this.refGameGame = returnRefGameMainImg();
        this.firstClick = false
        this.userLocale = this.props.userLang;
    }

    getGameDaata() {
        let aaaGameCount = 0
        let usualGameCount = 0
        let gameBlockOne = []
        let gameBlockTwo = []
        let gameBlockThree = []
        let gameBlockFoo = []
        const params = {headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.userLocale,
            "Content-Language": this.userLocale,
        }}
        fetch(HOST + 'gameinfo/game/', params)
            .then(response => response.json())
            .then(json => {
                var dateNow = new Date().toISOString().split("T")[0];
                dateNow = Date.parse(dateNow);
                for (let i = 0; i < json.hot_games.length; i++) {
                    let obj = json.hot_games[i]

                    // let releasedGameInfo = obj.readable_status.indexOf('Не')
                    // let releasedGameInfoEn = obj.readable_status.indexOf('Not')
                    // let releasedGame = obj.readable_status.indexOf('РЕЛИЗ')
                    // let releasedGameEn = obj.readable_status.indexOf('REL')
                    const releaseDate = Date.parse(obj.release_date);
                    if (!obj.crack_date && releaseDate == dateNow) {
                        obj.crackedGame = 'game-cracked-un-home'
                    } else if (obj.crack_date) {
                        obj.crackedGame = 'game-cracked-yes-home'
                    } else if (!obj.crack_date && releaseDate < dateNow) {
                        obj.crackedGame = 'game-cracked-no-home'
                    } else {
                        obj.crackedGame = 'game-cracked-un-home'
                    }
                    // if (releasedGameInfo != -1 || releasedGameInfoEn != -1) {
                    //     obj.crackedGame = 'game-cracked-no-home'
                    // } else if (releasedGame != -1 || releasedGameEn != -1) {
                    //     obj.crackedGame = 'game-cracked-un-home'
                    // } 
                    // else {
                    //     obj.crackedGame = 'game-cracked-yes-home'
                    // }

                    if (obj.is_AAA) {   
                        if (aaaGameCount == 0) {
                            Store.dispatch({type: 'ONEAGAME', value: [obj]})
                        } else if (aaaGameCount == 1) {
                            Store.dispatch({type: 'TWOAGAME', value: [obj]})
                        } else if (aaaGameCount == 2) {
                            Store.dispatch({type: 'THREEAAGAME', value: [obj]})
                        } else if (aaaGameCount == 3) {
                            Store.dispatch({type: 'FOOAGAME', value: [obj]})
                        }
                        aaaGameCount +=1
                        continue
                    }
                    if (usualGameCount >= 0 && usualGameCount <= 3) {
                        gameBlockOne.push(obj)
                    } else if (usualGameCount >= 4 && usualGameCount <= 7) {
                        gameBlockTwo.push(obj)
                    } else if (usualGameCount >= 8 && usualGameCount <= 11) {
                        gameBlockThree.push(obj)
                    } else if (usualGameCount >= 12 && usualGameCount <= 15) {
                        gameBlockFoo.push(obj)
                    }
                    usualGameCount += 1
                }
                Store.dispatch({type: 'UNRELEAZEDGAME', value: json.unreleased_game})
                Store.dispatch({type: 'FIRSTGAMEBLOCK', value: gameBlockOne})
                Store.dispatch({type: 'SECONDGAMEBLOCK', value: gameBlockTwo})
                Store.dispatch({type: 'THREEGAMEBLOCK', value: gameBlockThree})
                Store.dispatch({type: 'FOOGAMEBLOCK', value: gameBlockFoo})
                Store.dispatch({type: 'REFGAME', value: json.ref_game})
                Store.dispatch({type: 'YOUTUBEVIDEO', value: json.youtube_video_id})
                Store.dispatch({type: 'POPULARCRACKGAME', value: json.popular_craked_games})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.count_tg_users})
                this.setState({load: true})
            })
    }

    clickOnRefGame = () => {
        fetch(HOST + 'gameinfo/game/countrefgame/')
    }

    componentDidMount() {
        this.getGameDaata()
        window.addEventListener('scroll', this.scrollHundler);
    }

    componentWillUnmount () {
        window.removeEventListener('scroll', this.scrollHundler);
    }

    scrollHundler = () => {
        if (this.divRef.current) {
            let scrollY = window.pageYOffset
            let heigghtDiv = this.divRef.current.offsetHeight
            //search for percent of the number
            let calc = scrollY / heigghtDiv * 100
            if (calc > 17 && !this.state.loadSecondBlockPopGames) {
                this.setState({loadSecondBlockPopGames: true})
            } else if (calc > 65 && !this.state.loadUnreleasedGame) {
                this.setState({loadUnreleasedGame: true})
            }
        }
    }

    changeZIndex = (id, type) => {
        if (type == 'add') {
            let elms = document.getElementById(id);
            elms.style.zIndex = '1000'
            let elmsVideo = document.getElementById(`${id}-video`);
            if (elmsVideo) {
                elmsVideo.style.display = 'block'
                elmsVideo.style.width = `${elms.offsetWidth}px`
                elmsVideo.style.height = `${elms.offsetHeight}px`
                elmsVideo.muted = true;
                elmsVideo.play()
            }
        } else {
            let elms = document.getElementById(id);
            elms.style.zIndex = '0' 
            let elmsVideo = document.getElementById(`${id}-video`);
            if (elmsVideo) {
                elmsVideo.style.display = 'none'
            }
        }
    }

    getStateBlockGame = (num) => {
        if (num == 1) {
            return this.state.blockOne
        } else if (num == 2) {
            return this.state.blockTwo
        } else if (num == 3) {
            return this.state.blockThree
        } else if (num == 4) {
            return this.state.blockFoo
        } else if (num == 5) {
            return this.state.blockFive
        } else if (num == 6) {
            return this.state.blockSix
        } else if (num == 7) {
            return this.state.blockSeven
        } else if (num == 8) {
            return this.state.blockEight
        } else if (num == 9) {
            return this.state.blockNine
        } else if (num == 10) {
            return this.state.blockTen
        } else if (num == 11) {
            return this.state.blockEleven
        } else if (num == 12) {
            return this.state.blockTwelve
        } else if (num == 13) {
            return this.state.blockThirteen
        } else if (num == 14) {
            return this.state.blockFourteen
        } else if (num == 15) {
            return this.state.blockFifteen
        } else if (num == 16) {
            return this.state.blockSixteen
        } else if (num == 17) {
            return this.state.blockSeventeen
        } else if (num == 18) {
            return this.state.blockEighteen
        } else if (num == 19) {
            return this.state.blockNineteen
        }
    }

    changeStateBlockGame = (num) => {
        if (num == 1) {
            this.setState({blockOne: true})
        } else if (num == 2) {
            this.setState({blockTwo: true})
        } else if (num == 3) {
            this.setState({blockThree: true})
        } else if (num == 4) {
            this.setState({blockFoo: true})
        } else if (num == 5) {
            this.setState({blockFive: true})
        } else if (num == 6) {
            this.setState({blockSix: true})
        } else if (num == 7) {
            this.setState({blockSeven: true})
        } else if (num == 8) {
            this.setState({blockEight: true})
        } else if (num == 9) {
            this.setState({blockNine: true})
        } else if (num == 10) {
            this.setState({blockTen: true})
        } else if (num == 11) {
            this.setState({blockEleven: true})
        } else if (num == 12) {
            this.setState({blockTwelve: true})
        } else if (num == 13) {
            this.setState({blockThirteen: true})
        } else if (num == 14) {
            this.setState({blockFourteen: true})
        } else if (num == 15) {
            this.setState({blockFifteen: true})
        } else if (num == 16) {
            this.setState({blockSixteen: true})
        } else if (num == 17) {
            this.setState({blockSeventeen: true})
        } else if (num == 18) {
            this.setState({blockEighteen: true})
        } else if (num == 19) {
            this.setState({blockNineteen: true})
        } 
    }

    renderMicrotrailer = (videoId, idDivVideo) => {
        if (videoId) {
            return (
                <video id={idDivVideo}>
                    <source src={`https://cdn.cloudflare.steamstatic.com/steam/apps/${videoId}/microtrailer.webm`} type="video/webm" />
                </video>
            )
        }
    }

    render() {
        if (!this.state.load) {
            return (
                <div>
                    <div className="home-main-container loader-distance">
                        <Loader />
                    </div>
                </div>
            )
        }
        let unreleasedGameNum = 0
        return (
            <div className="main-wrapper">
                    <LeftSideAds />
                    <RightSideAds />
                    <Helmet>
                        <title>{STRINGLOCAL.home_title}</title>
                        <link rel="alternate" hreflang={getAnotherLangPage(this.userLocale)} href={`https://gamestatus.info/${getAnotherLangPage(this.userLocale)}`} />
                        <link rel='canonical' href={`https://gamestatus.info/${this.userLocale}`} data-react-helmet='true' />
                        <meta
                            name="description"
                            content={STRINGLOCAL.home_desc}
                        />
                        <meta 
                            property="og:description" 
                            content={STRINGLOCAL.home_desc}
                            data-react-helmet='true' 
                        />
                        <meta property="og:title" content={STRINGLOCAL.site_name} data-react-helmet='true' />
                        <meta property="og:image" content="https://gamestat.s3.eu-west-2.amazonaws.com/logo_preview.png" data-react-helmet='true' />
                        <meta property="og:site_name" content="GameStatus" />

                        <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                    </Helmet>
                    <div className="home-main-container main-background">
                        <TopBar showTgHero={false} />
                        <OfflineActBanner />
                        <div className="home-page-text home-page-title" id="popular-cracked-game">
                            <p className="system-req-text">{STRINGLOCAL.mostViewed}</p>
                        </div>

                        <div className="wrapper-popular-cracked-game">
                            {this.props.popularCrakedGames.map((obj, i) => {
                                i +=14
                                let idValue = `popular-crack-game-${i}-img`
                                let idValueImg = `popular-crack-game-${i}-img`
                                let idValueVideo = `popular-crack-game-${i}-img-video`
                                return (
                                    <div  className="small-game-cont  middle-game-cont" key={i}>
                                        <div className='image-wrapper' onMouseEnter={() => this.changeZIndex(idValue, 'add')} onMouseLeave={() => this.changeZIndex(idValue, 'remove')}>
                                            <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                <img id={idValueImg} src={obj.short_image}
                                                    alt={obj.title}
                                                    className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                    onLoad={() => {this.changeStateBlockGame(i)}} 
                                                />
                                                {/* {this.renderMicrotrailer(obj.steam_media_id, idValueVideo)} */}
                                            </a>
                                        </div>
                                        <p className="label-game-status game-cracked-home">
                                            {obj.readable_status}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>

                        <TelegramHeroBlock />

                        {/* <div className="ads-wrapper">
                            <Adsense
                                client="ca-pub-6277942134397670"
                                slot="6713999859"
                                style={{ display: 'inline-block', width: "728px", height: "90px"}}
                            />
                        </div>
                        <div className="ads-wrapper ads-wrapper-mini">
                            <Adsense
                                client="ca-pub-6277942134397670"
                                slot="2570385490"
                                style={{ display: 'inline-block', width: "350px", height: "120px"}}
                            />
                        </div> */}
                        {/* <MiddleAds /> */}

                        <div className="home-page-text home-page-title">
                            <h1 className="system-req-text">{STRINGLOCAL.new_cracked_game}</h1>
                        </div>
                        <div className="top-games" ref={this.divRef}>

                        <div className="news-home-prewiw first-hero-bl hero-bl">
                            <div className="hero-bl-wrapper hero-bl-wrapper-home">
                                <Link to={"/releasedgame" + '/' + this.userLocale}><p>{STRINGLOCAL.game_released_on_pc}</p></Link>
                            </div>
                        </div>

                            <div className="game-block-one">

                                {this.props.first_aaa_game.map((obj, i) => {
                                    i +=1
                                    return (
                                        <div className="big-game-cont" key={i}>
                                            <div className='image-wrapper'>
                                                <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                    <img src={obj.short_image}
                                                        alt={obj.title}
                                                        className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                        onLoad={() => {this.changeStateBlockGame(i)}} 
                                                    />
                                                </a> 
                                            </div>
                                            {obj.is_offline_act ? <span className='aaa-lable off-act-lable'>{STRINGLOCAL.offlineActivate}</span> : ''}
                                            <span className='aaa-lable'>AAA GAME</span>
                                            <p className="game-name">{obj.title}</p>
                                            <p className={"label-game-status game-cracked-home " + obj.crackedGame}>
                                                {obj.readable_status}
                                            </p>
                                            <div className="release-date">
                                                <img src='/calendar-1.svg' />
                                                <span>{obj.release_date.substr(0, 10)}</span>
                                            </div>
                                        </div>
                                    )
                                })}

                                <div className="wrapper-small-game">
                                    {this.props.game_block_one.map((obj, i) => {
                                        i +=2
                                        let idValue = `first-block-${i}`
                                        let idValueImg = `first-block-${i}-img`
                                        let idValueVideo = `first-block-${i}-video`
                                        return (
                                            <div id={idValue} className="small-game-cont" key={i}>
                                                <div className='image-wrapper' onMouseEnter={() => this.changeZIndex(idValue, 'add')} onMouseLeave={() => this.changeZIndex(idValue, 'remove')}>
                                                    <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                        <img id={idValueImg} src={obj.short_image}
                                                            alt={obj.title}
                                                            className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                            onLoad={() => {this.changeStateBlockGame(i)}} 
                                                        />
                                                        {this.renderMicrotrailer(obj.steam_media_id, idValueVideo)}
                                                    </a>
                                                </div>
                                                <p className="game-name">{obj.title.substr(0, 24)}</p>
                                                <p className={"label-game-status game-cracked-home " + obj.crackedGame}>
                                                    {obj.readable_status}
                                                </p>
                                                {/* <div className="release-date release-date-small">
                                                    <img src='/calendar-1.svg' />
                                                    <span>{obj.release_date.substr(0, 10)}</span>
                                                </div> */}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
{/* 
                            <div className="wrapper-referrals-game">
                                {this.props.refGame.map((obj, i) => {
                                    return (
                                    <div className="referrals-game" key={i} onClick={() => this.clickOnRefGame()}>
                                        <a href={`${obj.url}?agent=${STEAMPAYREFERRAL}`} target="_blank">
                                            <img src={obj.image} />
                                        </a>
                                        <div className="wrapper-referrals-game-text">
                                            <p className="discount-game">{obj.price} ₽</p>
                                        </div>
                                    </div>
                                    )
                                })}
                            </div> */}

                            {window.innerWidth <= 700 ? 
                                <MiddleAdsShort /> : ''
                            }

                            <div className="news-home-prewiw first-hero-bl second-hero-bl hero-bl">
                                <div className="hero-bl-wrapper hero-bl-wrapper-home">
                                    <Link to={"/gamecalendar" + '/' + this.userLocale}><p>{STRINGLOCAL.calendar_home_page}</p></Link>
                                </div>
                            </div>

                            <div className="game-block-one game-block-two">

                                <div className="wrapper-small-game">
                                    {this.props.game_block_two.map((obj, i) => {
                                        i +=6
                                        if (this.state.loadSecondBlockPopGames || window.screen.width >= 500) {
                                            let idValue = `second-block-${i}`
                                            let idValueImg = `second-block-${i}-img`
                                            let idValueVideo = `second-block-${i}-video`
                                            return (
                                                <div id={idValue} className="small-game-cont" key={i}>
                                                    <div className='image-wrapper' onMouseEnter={() => this.changeZIndex(idValue, 'add')} onMouseLeave={() => this.changeZIndex(idValue, 'remove')}>
                                                        <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                            <img id={idValueImg} src={obj.short_image}
                                                                alt={obj.title}
                                                                className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                                onLoad={() => {this.changeStateBlockGame(i)}} 
                                                            />
                                                            {this.renderMicrotrailer(obj.steam_media_id, idValueVideo)}
                                                        </a>
                                                    </div>
                                                    <p className="game-name">{obj.title.substr(0, 24)}</p>
                                                    <p className={"label-game-status game-cracked-home " + obj.crackedGame}>
                                                        {obj.readable_status}
                                                    </p>
                                                    {/* <div className="release-date release-date-small">
                                                        <img src='/calendar-1.svg' />
                                                        <span>{obj.release_date.substr(0, 10)}</span>
                                                    </div> */}
                                                </div>
                                            )
                                        }
                                    })}   
                                </div>

                                {this.props.two_aaa_game.map((obj, i) => {
                                    i +=7
                                    if (this.state.loadSecondBlockPopGames || window.screen.width >= 500) {
                                        return (
                                            <div className="big-game-cont" key={i}>
                                                <div className='image-wrapper'>
                                                    <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                        <img src={obj.short_image}
                                                            alt={obj.title}
                                                            className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                            onLoad={() => {this.changeStateBlockGame(i)}} 
                                                        />
                                                    </a>
                                                </div>
                                                {obj.is_offline_act ? <span className='aaa-lable off-act-lable'>{STRINGLOCAL.offlineActivate}</span> : ''}
                                                <span className='aaa-lable'>AAA GAME</span>
                                                <p className="game-name">{obj.title}</p>
                                                <p className={"label-game-status game-cracked-home " + obj.crackedGame}>
                                                    {obj.readable_status}
                                                </p>
                                                <div className="release-date">
                                                    <img src='/calendar-1.svg' />
                                                    <span>{obj.release_date.substr(0, 10)}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}

                            </div>

                            {/* <div className="ads-wrapper ads-wrapper-bottom">
                                <Adsense
                                    client="ca-pub-9213709847151410"
                                    slot="9609214407"
                                    style={{ display: 'inline-block', width: "728px", height: "90px"}}
                                />
                            </div>
                            <div className="ads-wrapper ads-wrapper-mini ads-wrapper-bottom">
                                <Adsense
                                    client="ca-pub-9213709847151410"
                                    slot="2851318063"
                                    style={{ display: 'inline-block', width: "350px", height: "120px"}}
                                />
                            </div> */}

                            <div className="news-home-prewiw first-hero-bl third-hero-bl hero-bl">
                                <div className="hero-bl-wrapper hero-bl-wrapper-home">
                                    <Link to={"/crackedgames" + '/' + this.userLocale}><p>{STRINGLOCAL.last_craked_game}</p></Link>
                                </div>
                            </div>

                            {/* <div className="div-middle-b-ads">
                                <div className="ads-wrapper">
                                <Adsense
                                    client="ca-pub-6277942134397670"
                                    slot="6713999859"
                                    style={{ display: 'inline-block', width: "728px", height: "90px"}}
                                />
                                </div>
                                <div className="ads-wrapper ads-wrapper-mini">
                                    <Adsense
                                        client="ca-pub-6277942134397670"
                                        slot="2570385490"
                                        style={{ display: 'inline-block', width: "350px", height: "120px"}}
                                    />
                                </div>
                            </div> */}

                            <div className="game-block-one game-block-three">

                                {this.props.three_aaa_game.map((obj, i) => {
                                    i += 11
                                    if (this.state.loadSecondBlockPopGames) {
                                        return (
                                            <div className="big-game-cont" key={i}>
                                                <div className='image-wrapper'>
                                                    <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                        <img src={obj.short_image}
                                                            alt={obj.title}
                                                            className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                            onLoad={() => {this.changeStateBlockGame(i)}} 
                                                        />
                                                    </a>
                                                </div>
                                                {obj.is_offline_act ? <span className='aaa-lable off-act-lable'>{STRINGLOCAL.offlineActivate}</span> : ''}
                                                <span className='aaa-lable'>AAA GAME</span>
                                                <p className="game-name">{obj.title}</p>
                                                <p className={"label-game-status game-cracked-home " + obj.crackedGame}>
                                                    {obj.readable_status}
                                                </p>
                                                <div className="release-date">
                                                    <img src='/calendar-1.svg' />
                                                    <span>{obj.release_date.substr(0, 10)}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                })}

                                <div className="wrapper-small-game">
                                    {this.props.game_block_three.map((obj, i) => {
                                        i += 12
                                        let idValue = `second-block-${i}`
                                        let idValueImg = `second-block-${i}-img`
                                        let idValueVideo = `second-block-${i}-video`
                                        if (this.state.loadSecondBlockPopGames) {
                                            return (
                                                <div id={idValue} className="small-game-cont" key={i}>
                                                    <div className='image-wrapper' onMouseEnter={() => this.changeZIndex(idValue, 'add')} onMouseLeave={() => this.changeZIndex(idValue, 'remove')}>
                                                        <a href={'/' + obj.slug + '/' + this.userLocale}>
                                                            <img id={idValueImg} src={obj.short_image}
                                                                alt={obj.title}
                                                                className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                                onLoad={() => {this.changeStateBlockGame(i)}} 
                                                            />
                                                            {this.renderMicrotrailer(obj.steam_media_id, idValueVideo)}
                                                        </a>
                                                    </div>
                                                    <p className="game-name">{obj.title.substr(0, 24)}</p>
                                                    <p className={"label-game-status game-cracked-home " + obj.crackedGame}>
                                                        {obj.readable_status}
                                                    </p>
                                                    {/* <div className="release-date release-date-small">
                                                        <img src='/calendar-1.svg' />
                                                        <span>{obj.release_date.substr(0, 10)}</span>
                                                    </div> */}
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                            </div>
                        </div>

                        {window.innerWidth <= 700 ? 
                            <MiddleAdsShort /> : ''
                        }

                        {/* {window.innerWidth >= 1200 ? 
                            <div className="ads-bottom">
                                <Adsense
                                    client="ca-pub-6277942134397670"
                                    slot="6477392482"
                                    format="fluid"
                                    layoutKey="-6t+ed+2i-1n-4w"
                                    style={{ display: "block", width: "48%"}}
                                />
                                <Adsense
                                    client="ca-pub-6277942134397670"
                                    slot="6477392482"
                                    format="fluid"
                                    layoutKey="-6t+ed+2i-1n-4w"
                                    style={{ display: "block", width: "48%"}}
                                />
                            </div> : ''
                        } */}

                        <div className='unreleased-game-block'>
                            <h2 className='unreleased-game-title system-req-text'>{STRINGLOCAL.upcoming_releases}</h2>
                            <div className='list-unreleased-game'>
                                {this.props.unreleased_game.map((obj, i) => {
                                    if (this.state.loadUnreleasedGame) {
                                        unreleasedGameNum += 1
                                        return (
                                            <a href={'/' + obj.slug + '/' + this.userLocale} key={i}>
                                                <div className='unreleased-game'>
                                                    <img src={obj.short_image} className='unrealeased-game-logo' alt={obj.title} />
                                                    <div className='unrealeased-game-data'>
                                                        <p className='game-name-text'>{STRINGLOCAL.game_name}</p>
                                                        <p className='game-name-title'>{obj.title}</p>
                                                        <p className='number-game'>{'#'+unreleasedGameNum}</p>
                                                        <div className='release-data'>
                                                            <div className='unrelease-date special-text'>
                                                                <p>{STRINGLOCAL.release_date}</p>
                                                                <p>{obj.release_date}</p>
                                                            </div>
                                                            <div className='unrealized-readable-status'>
                                                                <p className='readable-status-text'>{obj.readable_status}</p>
                                                            </div>
                                                        </div>
                                                    </div> 
                                                </div>
                                            </a>
                                        )
                                    }

                                })}
                            </div>
                            <h2 className="text-hot-game small-text">
                                {STRINGLOCAL.home_buttom}
                            </h2>
                        </div>
                        <BottomInfo />
                    </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        top_games: state.top_games,
        first_aaa_game: state.first_aaa_game,
        two_aaa_game: state.two_aaa_game,
        three_aaa_game: state.three_aaa_game,
        foo_aaa_game: state.foo_aaa_game,
        game_block_one: state.game_block_one,
        game_block_two: state.game_block_two,
        game_block_three: state.game_block_three,
        game_block_foo: state.game_block_foo,
        unreleased_game: state.unreleased_game,
        refGame: state.refGame,
        userLang: state.userLang,
        popularCrakedGames: state.popularCrakedGames,
        countTgUsers: state.tgCountUsers,
    }
}

export default connect(mapStateToProps)(HomePage);