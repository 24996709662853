import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import HomePage from './components/homepage/homepage.js';
import Store from './modules/store.js';
import LoginPage from './components/loginpage/login-page.js';
import RegiserPage from './components/registerpage/register-page.js';
import ResetPassword from './components/resetpassword/reset-password.js';
import ConfirmComplete from './components/emailconfirm/confirm-email.js';
import Profile from './components/profile/profile.js';
import ChangePassword from './components/change-password/change-pass.js';
import GameCalendar from './components/game-calendar/game-calendar.js';
import AboutUs from './components/about-us/abou-us.js';
import News from './components/news/news.js';
import { createBrowserHistory } from "history";
import NewsDetail from './components/news-detail/news-detail.js';
import OauthLoginPage from './components/oauthlogin/oautglogin.js';
import CrackedGames from './components/cracked-games/cracked-game.js';
import PopularGameLiteGame from './components/weak-game/popular-пame-lite-game.js';
import ReleasedGame from './components/released_game/released_game.js';
import MainRouer from './main-router.js'
import PrivacyPolicy from './components/privacy-policy/privacy-policy.js'
import DetailGame from "./components/detail-game/detail-game.js";
import RemoveAdsRedirect from "./components/remove-ads/remove-ads-redirect.js";
import ToRemoveAds from "./components/remove-ads/to-remove-ads.js";

const history = createBrowserHistory();

// Sentry.init({
//   dsn: "https://a3b51660554e415aa26d60d5b2427a20@o457482.ingest.sentry.io/6477082",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });


ReactDOM.render(
  <Provider store={Store}>
    <BrowserRouter>
      <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/en" component={HomePage} />
          <Route path="/ru" component={HomePage} />
          <Route path="/zh" component={HomePage} />
          <Route path="/sign-in:lang?" component={LoginPage} />
          {/* <Route path="/registration" component={RegiserPage} /> */}
          {/* <Route path="/reset-password" component={ResetPassword} />
          <Route path="/email-confirmed" component={ConfirmComplete} /> */}
          <Route path="/profile:lang?" component={Profile} />
          {/* <Route path="/change-password" component={ChangePassword} /> */}
          <Route path="/gamecalendar:lang?" component={GameCalendar} />
          {/* <Route path="/news-detail:lang?" component={NewsDetail} /> */}
          <Route path="/disable-ads" component={ToRemoveAds} />
          <Route path="/disabled-ads" component={RemoveAdsRedirect} />
          <Route path="/about-us" component={AboutUs} />
          <Route path="/oauthlogin" component={OauthLoginPage} />
          <Route path="/crackedgames:lang?" component={CrackedGames} />
          <Route path="/popular-games-weak-pc/:lang?" component={PopularGameLiteGame} />
          <Route path="/releasedgame:lang?" component={ReleasedGame} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/newsdetail/:slug/:lang?" component={NewsDetail} />
          <Route path="/news:lang?" component={News} />
          <Route path="/:slug/:lang?" component={DetailGame} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
