import React, { Component } from 'react';

import './loader.css'

class Loader extends Component {

    render() {
        return (
            <div className='loader-wriper'>
                <div className="lds-ripple">
                    <div></div><div></div>
                </div>
            </div>
        )
    }
}

export default Loader;