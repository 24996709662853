import React, { Component } from 'react';

import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import InfiniteScroll from 'react-infinite-scroll-component';
import { withRouter } from "react-router-dom";
import {Helmet} from "react-helmet";
import { 
    FacebookShareButton, 
    FacebookIcon,
    VKShareButton,
    VKIcon
} from "react-share";
import { Adsense } from '@ctrl/react-adsense';
import Slider from "react-slick";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCartPlus, faSmileBeam, faSearch, faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import CountUp from 'react-countup';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Picker from 'emoji-picker-react';
import ReactPlayer from 'react-player'


import './detail-game.css'
import TopBar from '../top-bar/top-bar.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import { HOST, COOKIES, DEBUG, ADMINUSERNAME, REFLINK, STRINGLOCAL } from '../../constants.js';
import Loader from '../loader/loader.js';
import Store from '../../modules/store.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import MiddleAds from '../middle-ads/middle-ads.js';
import MiddleAdsShort from '../middle-ads/middle-ads-chort.js';
import YoutubeNewsGame from '../youtube_news/youtube_news.js';
import DetailGameInfo from './detail-game-info.js';
import NoAdsPopUp from '../remove-ads/remove-ads.js';
import { Link } from 'react-router-dom';
import { returnRefGameImg, returnRefGameMainImg, newsWrapperListItmName } from '../utils';
import { getAnotherLangPage } from '../utils';


class DetailGame extends Component {

    constructor(props) {
        super(props)
        this.state = {
            notFound: false, loaded: false, errComment: false, 
            isSubscribe: false, reportedComment: [], loadedImg: false,
            refDetail: false, showRefBlock: false,
            uaRegion: false, showGifPopup: false, nameListNews: null,
            imgNewsOne: false, imgNewsTwo: false, imgNewsThree: false, 
            imgNewsFoo: false, imgNewsFive: false, imgNewsSix: false, 
            imgNewsSeven: false, imgNewsEight: false,
        }
        this.nextPage = ''
        this.hasMore = true
        this.jwt = COOKIES.get('jwt_token')
        this.commentRef = React.createRef();
        this.maxWidth = window.innerWidth;
        this.imgRefGame = returnRefGameImg(this.maxWidth);
        this.refGameGame = returnRefGameMainImg();
        this.countLoad = 0
        this.mainCommentId = null
        this.mainCommentUsername = null
        this.settingsSlick = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            autoplay: true,
            autoplaySpeed: 5000,
            centerMode: true,
            arrows: true
        }
        this.playerRef = React.createRef();
        this.firstClick = false
        this.userLocale = this.props.userLang
        this.renderedBlockComm = false
    }

    clickOnRefGame = () => {
        fetch(HOST + 'gameinfo/game/countrefgame/')
    }

    getRegioon = () => {
        fetch('https://api.freegeoip.app/json/?apikey=7ae45300-2944-11ec-ab4b-53624e0fcd82')
        .then((response) => {
            if (response.ok) {
                return response.json()
            }
        })
        .then(json => {
            if (json.country_code == 'UA') {
                this.setState({uaRegion: true})
            }
        })
    }

    getGameInfo = () => {
        //check on game name
        let path = window.location.href
        let gameName = path.split('/')
        gameName = gameName[3]
        if (!gameName) {
            this.setState({notFound: true})
        }
        //get info about a game
        let params = COOKIES.get('jwt_token') ? { headers: {
            "Content-Type": 'application/json',
            'Authorization': 'JWT ' + this.jwt,
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,

        }} : { headers: {
                        'Content-Type': 'application/json',
                        'Accept-Language': this.userLocale,
                        'Content-Language': this.userLocale,
                    }}
        fetch(HOST + 'gameinfo/game/'+ gameName + '/', params)
            .then((response) => {
                if (!response.ok) {
                    throw new Error(response.statusCode);
                } else {
                    return response.json()
                }}
            )
            .then(json => {
                let metacriticCss = ''
                let userScoreCss = ''
                if (json.mata_score > 70) {
                    metacriticCss = 'game-cracked'
                } else if (json.mata_score > 40) {
                    metacriticCss = 'game-middle'
                } else if (json.mata_score > 0) {
                    metacriticCss = 'game-uncracked'
                }
                json.metacriticCss = metacriticCss
                //css class for userscore
                if (json.user_score > 7) {
                    userScoreCss = 'game-cracked'
                } else if (json.user_score > 4) {
                    userScoreCss = 'game-middle'
                } else if (json.user_score > 0) {
                    userScoreCss = 'game-uncracked'
                }
                json.userScoreCss = userScoreCss
                //let additionalText = " Ресурс denuvo.net имеет большое количество положительных отзывов, поэтому вы можете приобрести некоторые товары у нас на сайте. Перед покупкой, обязательно прочитайте описание."
                //json.referrals_game[0]['add_info'] = json.referrals_game[0]['add_info'] += additionalText
                Store.dispatch({type: 'DETAILGAME', value: json})
                Store.dispatch({type: 'YOUTUBEVIDEO', value: json.youtube_video_id})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.count_tg_users})
                Store.dispatch({type: 'LISTRELATEDNEWS', value: json.list_related_news})
                this.setState({loaded: true, isSubscribe: json.is_subscribe})
                return
            })
            .catch((error) => {
                this.props.history.push('/')
            });
    }

    getGameComment = () => {
        let path = window.location.href
        let gameName = path.split('/')
        gameName = gameName[3]
    
        if (gameName) {
            var url = this.nextPage || HOST + 'gameinfo/comment/'+ gameName + '/'
            if (!DEBUG && !url.includes('https')) {
                url = url.replace('http', 'https')
            }
            let params = COOKIES.get('jwt_token') ? { headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'JWT ' + this.jwt,
                    'Accept-Language': this.userLocale,
                    'Content-Language': this.userLocale,
                }} : {headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": this.userLocale,
                    'Accept-Language': this.userLocale,
                    'Content-Language': this.userLocale,
                }}
            fetch(url, params)
                .then((response) => {
                    if (!response.ok) {
                        this.setState({notFound: true, loaded: false})
                    } else {
                        return response.json()
                    }
                })
                .then(json => {
                    this.nextPage = json.next
                    if (!this.nextPage) {
                        this.hasMore = false
                    }
                    let commentList = this.props.comment_list.slice()
                    commentList.push(...json.results)
                    Store.dispatch({type: 'COMMENTGAME', value: commentList})
                })
                .catch(error => {
                    return Promise.reject()
                })
        }
    }

    responseCommentHundler = (username, commentId) => {
        this.mainCommentUsername = [`[${username}]: &nbsp;`, username] 
        this.commentRef.current.innerHTML = this.mainCommentUsername[0]
        this.mainCommentId = commentId
        this.commentRef.current.scrollIntoView()  
    }

    handlePostComment = () => {
        //post comment
        let message = this.commentRef.current.innerHTML
        let countGif = message.split("src=").length - 1
        if (!message || message.length < 10) {
            this.setState({errComment: true})
            return
        }
        if (countGif > 2) {
            this.setState({errComment: true})
            return
        }
        if (this.mainCommentId) {
            var data = {"game": this.props.detail_game.slug, "text": message.replace(`[${this.mainCommentUsername[1]}]:&nbsp;`, `<span>${this.mainCommentUsername[1]}: </span>`), "child_comment": this.mainCommentId}
            this.mainCommentId = null
        } else {
            var data = {"game": this.props.detail_game.slug, "text": message}
        }
        fetch(HOST + 'gameinfo/comment/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + this.jwt,
                'Accept-Language': this.userLocale,
                'Content-Language': this.userLocale,
            }
        })
            .then((response) => response.json())
            .then(json => {
                let commentList = this.props.comment_list.slice()
                commentList.unshift(...[json])
                Store.dispatch({type: 'COMMENTGAME', value: commentList})
                this.setState({errComment: false})
                this.commentRef.current.innerHTML= ''
            })
    }

    handleRateComment = (action, commentObj) => {
        //update rete comment
        let data = {"rate": action}
        fetch(HOST + 'gameinfo/comment/' + commentObj.id + '/', {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + this.jwt
            }
        })
            .then((response) => {
                if (response.ok) {
                    let commentList = this.props.comment_list.slice()
                    let indexCommentObj = commentList.indexOf(commentObj)
                    let newRate = 0
                    if (action == 'up') {
                        newRate += commentObj.rate + 1
                    } else {
                        newRate += commentObj.rate - 1
                    }
                    commentList[indexCommentObj].rate = newRate
                    commentList[indexCommentObj].is_liked = true
                    Store.dispatch({type: 'COMMENTGAME', value: commentList})
                }
            })
    }

    hundleReportOnComment = (objComment) => {
        fetch(HOST + 'gameinfo/comment/' + objComment.id + '/report/', {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'JWT ' + this.jwt
            }
        })
            .then((response) => {
                if (response.ok) {
                    let reportedCommentList = this.state.reportedComment
                    reportedCommentList.push(objComment.id)
                    this.setState({reportedComment: reportedCommentList})
                }
            })
    }

    componentDidMount() {
        this.getGameInfo()
        // this.getGameComment()
    }

    componentWillUnmount() {
        Store.dispatch({type: 'COMMENTGAME', value: []})
    }

    evenNum = (value) => {
        return !(value % 2)
    }

    refImageLoad = (value) => {
        this.countLoad += 1
        if (this.props.detail_game.referrals_game.length == this.countLoad) {
            this.setState({showRefBlock: true})
        }
    }

    onEmojiClick = (event, emojiObject) => {
        let emoji = emojiObject.emoji
        this.commentRef.current.innerHTML += ` ${emoji} `
    }

    onGifClick = (gif_url) => {
        this.commentRef.current.innerHTML += ` <img src=${gif_url} width=150px; /> `
    }

    // getTopGif = () => {
    //     fetch("https://gif.gamestatus.info/top-gif")
    //     .then((response) => response.json())
    //     .then(json => {
    //         let gifList = []
    //         for (var i = 0; i < json['gif'].length; i++) {
    //             gifList.push(json['gif'][i]);
    //         }
    //         Store.dispatch({type: 'APPENDGIFLIST', value: gifList})
    //     })
    // }

    async searchGif(event) {
        let searchTitle = event.target.value
        if (!searchTitle) {
            Store.dispatch({type: 'APPENDGIFLIST', value: []})
        }
        Store.dispatch({type: 'APPENDGIFLIST', value: []})
        const data = {"key": searchTitle}
        fetch(`https://gif.gamestatus.info/gif-search?key=${searchTitle}`)
        .then((response) => response.json())
        .then(json => {
            let gifList = []
            for (var i = 0; i < json['gif'].length; i++) {
                gifList.push(json['gif'][i]);
            }
            Store.dispatch({type: 'APPENDGIFLIST', value: gifList})
        })
    }

    // openGifPopup = () => {
    //     this.setState({showGifPopup: true})
    //     this.getTopGif()
    // }

    getOfflineActivationInfo = (locale) => {
        if (locale == 'ru') {
            return (
                <div className="detail-ref-game">
                    {this.props.detail_game.referrals_game[0].info_goods ? <div className="ref-conditions-description"><span className="describe-ref-game">{STRINGLOCAL.conditionsPurchace} </span> <span className="game-info-key" dangerouslySetInnerHTML={{__html:this.props.detail_game.referrals_game[0].info_goods}} /></div> : ''}
                    {this.props.detail_game.referrals_game[0].add_info ? <div className="ref-conditions-description"><span className="describe-ref-game">{STRINGLOCAL.conditionsPurchaceAdditionals} </span> <span className="game-info-key" dangerouslySetInnerHTML={{__html:this.props.detail_game.referrals_game[0].add_info}} /></div> : ''}
                </div>
            )
        } else {
            return (
                <div className="detail-ref-game">
                    {this.props.detail_game.referrals_game[0].info_goods_en ? <div className="ref-conditions-description"><span className="describe-ref-game">{STRINGLOCAL.conditionsPurchace} </span> <span className="game-info-key" dangerouslySetInnerHTML={{__html:this.props.detail_game.referrals_game[0].info_goods_en}} /></div> : ''}
                    {this.props.detail_game.referrals_game[0].add_info_en ? <div className="ref-conditions-description"><span className="describe-ref-game">{STRINGLOCAL.conditionsPurchaceAdditionals} </span> <span className="game-info-key" dangerouslySetInnerHTML={{__html:this.props.detail_game.referrals_game[0].add_info_en}} /></div> : ''}
                </div>
            )
        }
    }

    getCoommonOrRelatedNews = () => {
        Store.dispatch({type: 'LISTNEWSGAME', value: STRINGLOCAL.videoListNews})
        var userLoc = this.userLocale
        const supLangNews = ["en", "ru"]
        if (!(userLoc in supLangNews)) {
            userLoc = "en"
        }
        return (
            <div className="news-wrapper-list">
                {this.props.detail_game.last_news.map((obj, i) => {
                    return (
                        <div className="news-wrapper-list-itm" key={i}><a href={`/newsdetail/` + obj.slug + "/" + userLoc} target="_blank"><p>{newsWrapperListItmName(obj.title)}</p></a></div>
                    )
                })}
            </div>
        )
    }

    onReady = () => {
        // if (!this.instance) {
        //     return
        // }
        // this.playerRef.current.seekTo(1, 'seconds');
    }

    renderVideoBlock = () => {
        if (!this.instance) {
            return
        }
        var current_width = window.innerWidth;
        if (current_width > 1170) {
            current_width = 1170
        }
        let unnecessaryPx = (current_width * 10) / 100
        current_width = current_width - unnecessaryPx
        var height = (current_width * 56.25) / 100
        if (this.props.detail_game.teaser_link) {
            const opts = {
                height: height,
                width: current_width,
              };
            return (
            <div className='video-container'>
                <p className='trailer-title trailer-title-no-shift'>{STRINGLOCAL.trailer}</p>
                <YouTube opts={opts} videoId={this.props.detail_game.teaser_link.split('watch?v=')[1]}  />
            </div>
            )
        } else if (this.props.detail_game.teaser_link_steam) {
            return (
                <div className='video-container'>
                    <p className='trailer-title trailer-title-no-shift'>{STRINGLOCAL.trailer}</p>
                    <ReactPlayer onReady={this.onReady} ref={this.playerRef} width={`${current_width}px`} height={`${height}px`} controls={true} url={this.props.detail_game.teaser_link_steam.replace("http://", "https://")} className="video-wrapper-steam" />
                </div>
            )
        }
    }

    renderRefBlock = () => {
        if (this.props.detail_game.referrals_game.length > 1) {
            return (
                <div>
                    <div className={this.state.showRefBlock ? "text-ref-game show-element" : "text-ref-game"}>
                        <p className='system-req-text'>{STRINGLOCAL.offlineActGameTitle}</p>
                        <p className="game-info-key add-padding">{STRINGLOCAL.offlineActGameDesc}</p>
                    </div>
                    <div className={this.state.showRefBlock ? "slick-cont-wrapper show-element" : "slick-cont-wrapper"}>
                        <Slider {...this.settingsSlick}>
                            {this.props.detail_game.referrals_game.map((obj, i) => {
                                return <div className="slick-cont" key={i} onClick={() => {this.clickOnRefGame()}}>
                                    <a href={'/' + obj.slug}>
                                        <img src={obj.image} onLoad={() => {this.refImageLoad(obj.slug)}} />
                                        {/* <p className={this.evenNum(i) ? 'even-num' : ''}>{STRINGLOCAL.buyGame} {obj.price +' ₽'} {obj.discount > 0 ? <span className="ref-discount-game">-{obj.discount}%</span> : ''}</p> */}
                                    </a>
                                </div>
                            })}
                        </Slider>
                    </div>
                </div>
        )}
    }

    // choiceSourceComm = (side) => {
    //     if (side == "tg") {
    //         this.setState({selectedSourceComm: 'tg'})
    //     } else if (side == "site") {
    //         this.setState({selectedSourceComm: 'site'})
    //     }
    // }

    renderCommentBlock() {
        if (!this.instance) {
            return
        }
        if (this.props.detail_game.tg_publish_post_id && !this.renderedBlockComm) {
            const queryString = window.location.search;
            this.renderedBlockComm = true
            if (queryString.includes("?comments=tg") || !queryString.includes("?comments")) {
                const queryString = window.location.search;
                const d = document.createElement('script');
                d.src = 'https://telegram.org/js/telegram-widget.js?22'
                d.setAttribute('data-comments-limit', 20)
                d.setAttribute('data-color', "1B8")
                d.setAttribute('data-dark', 1)
                d.setAttribute('data-telegram-discussion', `gamestatusinfo/${this.props.detail_game.tg_publish_post_id}`)
                d.async = true;
                this.instance.appendChild(d);
                return
            }

        } else if (!this.renderedBlockComm) {
            const queryString = window.location.search;
            const d = document.createElement('script');
            d.src = 'https://telegram.org/js/telegram-widget.js?22'
            d.setAttribute('data-color', "1B8")
            d.setAttribute('data-dark', 1)
            d.setAttribute('data-telegram-post', `gamestatusinfo/1232`)
            d.async = true;
            this.instance.appendChild(d);
            return (
                <div className='comment-list'>
                    <a className="no-comments" target="_blank" href="https://t.me/gamestatusinfo">
                        <p>{STRINGLOCAL.noCommentsOne}</p>
                        <p>{STRINGLOCAL.noComments}</p>
                    </a>
                </div>
            )
        }
    }

    getStateBlockGame = (num) => {
        if (num == 1) {
            return this.state.imgNewsOne
        } else if (num == 2) {
            return this.state.imgNewsTwo
        } else if (num == 3) {
            return this.state.imgNewsThree
        } else if (num == 4) {
            return this.state.imgNewsFoo
        } else if (num == 5) {
            return this.state.imgNewsFive
        } else if (num == 6) {
            return this.state.imgNewsSix
        } else if (num == 7) {
            return this.state.imgNewsSeven
        } else if (num == 8) {
            return this.state.imgNewsEight
        }
    }

    changeStateBlockGame = (num) => {
        if (num == 1) {
            this.setState({imgNewsOne: true})
        } else if (num == 2) {
            this.setState({imgNewsTwo: true})
        } else if (num == 3) {
            this.setState({imgNewsThree: true})
        } else if (num == 4) {
            this.setState({imgNewsFoo: true})
        } else if (num == 5) {
            this.setState({imgNewsFive: true})
        } else if (num == 6) {
            this.setState({imgNewsSix: true})
        } else if (num == 7) {
            this.setState({imgNewsSeven: true})
        } else if (num == 8) {
            this.setState({imgNewsEight: true})
        }
    }

    cropText = (text) => {
        if (text.length > 85) {
            return `${text.substr(0, 83)}...`
        }
        return text
    }

    renderRelSteamNews() {
        if (this.props.userLang != 'ru') {
            if (this.props.listRelatedNews.length) {
                return (
                    <div>
                        <p className='system-req-text'>{this.props.detail_game.title} News</p>
                        <div className='steam-news-list'>
                            {this.props.listRelatedNews.map((obj, i=1) => {
                                return (
                                    <a href={obj.slug} target='_blank'>
                                        <div className='steam-news-card'>
                                            <img
                                                src={obj.img}
                                                className={this.getStateBlockGame(i) ? 'image-loaded' : 'image-load'}
                                                onLoad={() => {this.changeStateBlockGame(i)}} 
                                                onError={e => {e.currentTarget.src = "https://f000.backblazeb2.com/b2api/v1/b2_download_file_by_id?fileId=4_z2e7e4b21ca5d741a71520113_f107b8cc097398f01_d20231022_m011849_c000_v0001066_t0020_u01697937529102"}}
                                             />
                                            <p>{this.cropText(obj.title)}</p>
                                        </div>
                                    </a>
                                )
                            })}
                        </div>
                    </div>
                )
            }
        }
    }

    render() {
        let currentWidth = window.screen.width
        if (currentWidth <= 735) {
            this.settingsSlick.slidesToShow = 1
            this.settingsSlick.arrows = false
        } else if (currentWidth <= 900) {
            this.settingsSlick.slidesToShow = 2
        }
        //return loader
        if (!this.state.loaded) {
            return (
                <div className='detail-game-main'>
                    <Loader />
                </div>
            )
        }
        //return 404 page
        if (this.state.notFound) {
            return (
                <div className='error-code-wrapper'>
                    <p className='error-code'>404</p>
                </div>
            )
        }
        // if (this.props.detail_game.referrals_game && this.props.detail_game.referrals_game.length == 1) {
        //     this.getRegioon()
        // }
        //return detail game page
        return (
            <div className='detail-game-wrapper'>
                {/* <LeftSideAds />
                <RightSideAds /> */}
                <Helmet>
                    <title>{this.props.detail_game.torrent_link ? `${STRINGLOCAL.downloadTorrent} ${STRINGLOCAL.crack}` : STRINGLOCAL.crack} {this.props.detail_game.title}: {STRINGLOCAL.minimal_req_crack}</title>
                    <meta property="og:title" content={this.props.detail_game.torrent_link ? `${STRINGLOCAL.downloadTorrent} ${STRINGLOCAL.crack} ${this.props.detail_game.title}: ${STRINGLOCAL.min_req_rel_date}` : `${STRINGLOCAL.crack} ${this.props.detail_game.title}: ${STRINGLOCAL.min_req_rel_date}`} data-react-helmet='true' />
                    <meta property="og:image" content={this.props.detail_game.full_image} data-react-helmet='true' />
                    <meta property="og:image:secure_url" content={this.props.detail_game.full_image} data-react-helmet='true' />
                    <link rel="alternate" hreflang={getAnotherLangPage(this.userLocale)} href={`https://gamestatus.info${this.props.detail_game.slug}/${getAnotherLangPage(this.userLocale)}`} />
                    <link rel='canonical' href={`https://gamestatus.info/${this.props.detail_game.slug}/${this.userLocale}`} data-react-helmet='true' />
                    <meta
                        name="description"
                        content={`${STRINGLOCAL.crack_status_sys_req} ${this.props.detail_game.description.slice(0,130)}`}
                    />
                    <meta 
                        property="og:description" 
                        content={`${STRINGLOCAL.crack_status_sys_req} ${this.props.detail_game.description.slice(0,130)}`}
                        data-react-helmet='true'
                    />
                    <meta property="og:site_name" content={`Gamestatus - ${STRINGLOCAL.crack} ${this.props.detail_game.title}`} data-react-helmet='true' />
                </Helmet>
                <div className='detail-game-main cracked-games main-background'>
                    <TopBar countTgUsers={this.props.countTgUsers} />
                        
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    {/* <MiddleAds /> */}
                    <div className='title-navigator'>
                        <Link to='/'><p>{STRINGLOCAL.home_page}</p></Link>
                        <p> {'>'} </p>
                        <h1>{this.props.detail_game.torrent_link ? `${STRINGLOCAL.downloadTorrent} ${STRINGLOCAL.crack}` : STRINGLOCAL.crack} {this.props.detail_game.title}</h1>
                    </div>
                    <div className='title-navigator title-navigator-min'>
                        <h1><span className="crack-game-text">{STRINGLOCAL.crack} </span>{this.props.detail_game.title}</h1>
                    </div>
                    <DetailGameInfo detail_game={this.props.detail_game} userLang={this.props.userLang} is_auth={this.props.is_auth} isSubscribe={this.state.isSubscribe} />
                    {/* <div className="button-block-nx-prew">
                        <a href="/crackedgames">
                            <div className="prew-button">
                                <span className="name-game-btn">{STRINGLOCAL.detail_game_last_crack_game}</span>
                            </div>
                        </a>
                        <a href="/releasedgame">
                            <div className="prew-button next-button">
                                <span className="name-game-btn">{STRINGLOCAL.detail_game_new_game}</span>
                            </div>
                        </a>
                    </div> */}
                    <div className="social-button">
                        <FacebookShareButton 
                                url={`https://gamestatus.info/${this.props.detail_game.slug}`}
                                quote={`Взлом ${this.props.detail_game.title} с защитой ${this.props.detail_game.protections} | Game Status`}
                            >
                            <FacebookIcon size={40} />
                        </FacebookShareButton>
                        <VKShareButton 
                                url={`https://gamestatus.info/${this.props.detail_game.slug}`}
                                title={`Взлом ${this.props.detail_game.title} с защитой ${this.props.detail_game.protections} | Game Status`}
                                image={this.props.detail_game.short_image}
                            >
                            <VKIcon size={40} />
                        </VKShareButton>
                        <div className="tg-ref-div">
                            <a href="https://t.me/gamestatusinfo" className="telegram-icon">
                                <img className="inst-icon" src="/lg_icon.png" />
                            </a>
                        </div>
                    </div>
                    {window.innerWidth <= 700 ? 
                        <MiddleAdsShort /> : ''
                    }
                    <p className='system-req-text list-news-game-title'>{this.props.listNewsGameTitle}</p>
                    <div className="middle-youtube-news">
                        <YoutubeNewsGame />
                        {this.getCoommonOrRelatedNews()}
                    </div>
                    {this.props.detail_game.referrals_game.length == 1 ?
                            // <div className='purchase-game'>
                            //     <div className='purchase-game-one'>
                            //         <p>{STRINGLOCAL.waitCrack}</p>
                            //     </div>
                            //     <div className='purchase-game-two' onClick={() => (window.open(`${this.props.detail_game.referrals_game[0].info_goods}?partner=972665`, '_blank'))}>
                            //         <div className='purchase-game-icon-wrapper'>
                            //             <div>{<FontAwesomeIcon className="ref-title-game color-bucket circle-purchase" icon={faCartPlus} />} </div>
                            //             <span> {STRINGLOCAL.offlineActPurchase}</span>
                            //         </div>
                            //     </div>
                            // </div>
                            <div>
                                <p className="system-req-text add-padding offline-actv-text">{STRINGLOCAL.waitCrack}</p>
                                <div className="download-game ref-title-text color-border">
                                    <a href={this.props.detail_game.referrals_game[0].info_goods + '?partner=622422'} target="_blank">
                                        <p className="system-req-text no-left-border bucket-text"><span>{<FontAwesomeIcon className="ref-title-game color-bucket" icon={faCartPlus} />} {STRINGLOCAL.purchace}</span> {STRINGLOCAL.offlive_activation_price} <span className="ref-title-game color-bucket"><CountUp end={this.props.detail_game.referrals_game[0].price} /> ₽</span></p>
                                    </a>
                                </div>
                                {this.props.detail_game.referrals_game[0].discount > 0 ? <p className="system-req-text discount-ref-game">-{this.props.detail_game.referrals_game[0].discount} %</p> : ''}
                            </div> 

                        : ''
                    }
                    {this.props.detail_game.description && this.userLocale.includes('ru') ?
                        <div className="desc-detail-game">
                            <p className='system-req-text'>{STRINGLOCAL.description}</p>
                            <h2 className="game-info-key">{this.props.detail_game.description}</h2>
                        </div> : ''
                    }
                    {this.props.detail_game.description_en && this.userLocale.includes('en') || this.userLocale.includes('zh') ?
                        <div className="desc-detail-game">
                            <p className='system-req-text'>{STRINGLOCAL.description}</p>
                            <h2 className="game-info-key">{this.props.detail_game.description_en}</h2>
                        </div> : ''
                    }
                    {this.renderRefBlock()}
                    {this.renderVideoBlock()}
                    {this.renderRelSteamNews()}
                    {Object.keys(this.props.detail_game.specs_info).length ?
                        <div>
                            <p className='system-req-text'>{STRINGLOCAL.req_for} {this.props.detail_game.title}</p>
                            <h3 className="game-info-key distance-sys-req">{STRINGLOCAL.system_req_detail} {this.props.detail_game.title}. {STRINGLOCAL.system_req_info}</h3>
                            <div className='system-info-block'>
                                {this.props.detail_game.specs_info.cpu_info && this.props.detail_game.specs_info.cpu_info.toLowerCase() != "info" && this.props.detail_game.specs_info.cpu_info.toLowerCase() != "tdb" ?
                                    <div className='system-info-block-param'>
                                        <p className='game-info-key'>{STRINGLOCAL.cpu}:</p>
                                        <p className='game-info-key game-info-specs'>{this.props.detail_game.specs_info.cpu_info}</p>
                                    </div>
                                    : ''
                                }
                                {this.props.detail_game.specs_info.ram_info && this.props.detail_game.specs_info.ram_info.toLowerCase() != "info" && this.props.detail_game.specs_info.ram_info.toLowerCase() != "tdb" ?
                                    <div className='system-info-block-param'>
                                        <p className='game-info-key'>RAM:</p>
                                        <p className='game-info-key game-info-specs'>{this.props.detail_game.specs_info.ram_info}</p>
                                    </div>
                                    : ''
                                }
                                {this.props.detail_game.specs_info.os_info && this.props.detail_game.specs_info.os_info.toLowerCase() != "info" && this.props.detail_game.specs_info.os_info.toLowerCase() != "tdb" ?
                                    <div className='system-info-block-param'>
                                        <p className='game-info-key'>OC:</p>
                                        <p className='game-info-key game-info-specs'>{this.props.detail_game.specs_info.os_info}</p>
                                    </div>
                                    : ''
                                }
                                {this.props.detail_game.specs_info.gpu_info && this.props.detail_game.specs_info.gpu_info.toLowerCase() != "info" && this.props.detail_game.specs_info.gpu_info.toLowerCase() != "tdb" ?
                                    <div className='system-info-block-param'>
                                        <p className='game-info-key'>{STRINGLOCAL.gpu}:</p>
                                        <p className='game-info-key game-info-specs'>{this.props.detail_game.specs_info.gpu_info}</p>
                                    </div>
                                    : ''
                                }
                            </div>
                        </div> :  ''}

                    {window.innerWidth <= 700 ? 
                        <MiddleAdsShort /> : ''
                    }
                    <div className='post-comment'>
                        <p className='comment-text'>{STRINGLOCAL.comment}</p>
                        {!this.props.detail_game.tg_publish_post_id ? <div className='comment-list-flex'>{this.renderCommentBlock()} <div className='comm-post-tg' ref={el => (this.instance = el)} /></div> : <div className='comment-list'>{this.renderCommentBlock()} <div ref={el => (this.instance = el)} /></div>}
                        {/* <div className='comment-list'> */}
                            {/* {this.renderCommentBlock()}
                            <div ref={el => (this.instance = el)} /> */}
                            {/* <div ref={el => (this.siteComm = el)} /> */}
                        {/* </div> */}
                        {/* {this.state.errComment ? <p className="err-message-detail-game">{STRINGLOCAL.err_mess_post_comment}</p> : ''} */}
                        {/* <div className={this.props.is_auth ? '' : 'show-content'}>
                            <div className={this.state.errComment ? 'leave-comment err-comment-block' : 'leave-comment'}>
                                <div className='leave-comment-avatar'>
                                    <img src='/user-avatar.svg' />
                                </div>
                                <div contentEditable="true" ref={this.commentRef} className="text-input" data-placeholder={STRINGLOCAL.leave_comment}></div>
                            </div> */}
                            {/* <div className="sticker-text"> */}
                                {/* <div className="sticker-name">
                                    <span onClick={() => this.openGifPopup()}>GIF</span>
                                    {this.state.showGifPopup ? 
                                    <div className="gif-block">
                                        <div className="gif-block-search">
                                            <FontAwesomeIcon className="ref-title-game icons-comment" icon={faSearch} />
                                            <input type="text" onChange={(e) => {this.searchGif(e)}} />
                                            <FontAwesomeIcon className="ref-title-game icons-comment" icon={faTimesCircle} onClick={() => {this.setState({showGifPopup: false})}} />
                                        </div>
                                        <div className="gif-list">
                                            <div className="gif-list-block">
                                                {this.props.gifList.map((obj, i) => {
                                                    return (<img src={obj} key={i} onClick={() => {this.onGifClick(obj)}} />)
                                                })}
                                            </div>
                                        </div>
                                    </div> : ''}
                                </div> */}
                                {/* <div className="sticker-name smile-block">
                                    <FontAwesomeIcon className="ref-title-game" icon={faSmileBeam} />
                                    <div className="smile-block-list">
                                        <Picker onEmojiClick={this.onEmojiClick} /> 
                                    </div>
                                </div>
                            </div>
                            <div className='wrapper-sent-button'>
                                <div className='string'></div>
                                <div>
                                    <div className='sent-comment-button internal-sent' onClick={this.handlePostComment}>{STRINGLOCAL.sent_comment}</div>
                                    {this.props.detail_game.tg_post_id ?
                                        <a href={`https://t.me/gamestatusinfo/${this.props.detail_game.tg_post_id}`} target="blank">
                                            <div className='sent-comment-button internal-sent-tg'>
                                                <img class="inst-icon" src="/lg_icon.png"/>{STRINGLOCAL.sentCommentTg}
                                            </div>
                                        </a> : ''
                                    }
                                </div>
                                <div className='string'></div>
                            </div>
                        </div> */}
                        {/* <div className='comment-list'>
                            <div className='choice-type-source-comm'>
                                <div className='left-choice-source-comm source-comm-choised' onClick={() => this.choiceSourceComm('tg')}>
                                    в TELEGRAM
                                </div>
                                <div className='right-choice-source-comm left-choice-source-comm' onClick={() => this.choiceSourceComm('site')}>
                                    на сайте
                                </div>
                            </div>
                            <div className='comment-part' ref={el => (this.instance = el)} />
                            <div className='comment-part' ref={el => (this.instance = el)} />
                        </div> */}
                    </div>
                </div>
                <BottomInfo />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        detail_game: state.detail_game,
        comment_list: state.comment_list,
        is_auth: state.is_auth,
        userLang: state.userLang,
        gifList: state.gifList,
        listNewsGameTitle: state.listNewsGame,
        countTgUsers: state.tgCountUsers,
        listRelatedNews: state.listRelatedNews,
    }
}

export default withRouter(connect(mapStateToProps)(DetailGame));