import React, { Component } from 'react';

import YouTube from 'react-youtube';
import Store from '../../modules/store.js';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";
import { 
    FacebookShareButton, 
    FacebookIcon,
    TelegramShareButton,
    TelegramIcon,
    VKShareButton,
    VKIcon
} from "react-share";
import { withRouter } from "react-router";
import { Adsense } from '@ctrl/react-adsense';
import { Link } from 'react-router-dom';

import '../news-detail/news-detail.css';
import './news-detail-steam.css';
import TopBar from '../top-bar/top-bar.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import DetailGameInfo  from '../detail-game/detail-game-info.js';
import { HOST, STRINGLOCAL } from '../../constants';
import Loader from '../loader/loader.js';
import { returnRefGameMainImg } from '../utils';

export class NewsDetailSteam extends Component {

    constructor(props) {
        super(props)
        this.state = {loaded: false, notFound: false, showPopUp: false}
        this.refGameGame = returnRefGameMainImg();
        this.firstClick = false
        this.userLocale = this.props.userLang
    }

    componentDidMount() {
        this.getNewsDeatil()
    }
    
    getNewsDeatil =() => {
        let path = window.location.href
        let newsName = path.split('newsdetail-steam-')[1]
        const params = { headers: {
            'Content-Type': 'application/json',
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,
        }}
        fetch(`${HOST}news/steamnews/${newsName}`, params)
            .then((response) => {
                if (!response.ok) {
                    this.setState({notFound: true, loaded: true})
                } else {
                    return response.json()
                }
            })
            .then(json => {
                let langForNews = json.news["lang"]
                if (langForNews != this.userLocale) {
                    this.props.history.push({pathname: '/'})
                }
                Store.dispatch({type: 'LATESTCRACKEDGAME', value: json.game_list})
                Store.dispatch({type: 'NEWSOBJ', value: json.news})
                Store.dispatch({type: 'PREVNEWSOBJ', value: json.game_list})
                Store.dispatch({type: 'DETAILGAME', value: json.detail_game})
                this.setState({loaded: true})
            })
            .catch(error => {
                return Promise.reject()
            })

    }

    render() {
        //return loader
        if (!this.state.loaded) {
            return (
                <div className='detail-game-main'>
                    <Loader />
                </div>
            )
        }
        //return 404 page
        if (this.state.notFound) {
            return (
                <div className='error-code-wrapper'>
                    <p className='error-code'>404</p>
                </div>
            )
        }

        return (
            <div className="news-wrapper">
                <LeftSideAds />
                <RightSideAds />
                <Helmet>
                    <title>{this.props.newsDetail.meta_title}</title>
                    <link rel="canonical" href={`https://gamestatus.info/newsdetail-steam-${this.props.newsDetail.slug}`} />
                    <meta property="og:description" content={this.props.newsDetail.meta_description} />
                    <meta name="description" content={this.props.meta_description} />
                    <meta property="og:title" content={this.props.newsDetail.meta_title} data-react-helmet='true' />
                    <meta property="og:image" content={this.props.newsDetail.preview} data-react-helmet='true' />
                    <meta property="og:image:secure_url" content={this.props.newsDetail.preview} data-react-helmet='true' />
                    <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                </Helmet>
                <div className='news-main'>
                    <TopBar />
                    <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div>

                    {window.innerWidth <= 700 ? 
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="8109699047"
                            format="fluid"
                            layoutKey="-ef+6m-2i-c0+uy"
                            style={{ display: "block"}}
                        /> : ''
                    }
                    <div className='title-navigator'>
                        <Link to='/'><p>{STRINGLOCAL.home_page}</p></Link>
                        <p> {'>'} </p>
                        <p>{STRINGLOCAL.videoListNews}</p>
                    </div>
                    <DetailGameInfo detail_game={this.props.detail_game} userLang={this.props.userLang} />

                    <div className="news-container">
                        <div className="news-preview news-steam">
                            <h1 className="news-title-detail">{this.props.newsDetail.title}</h1>
                        </div>

                        <div className="news-body">
                            <p dangerouslySetInnerHTML={{__html: this.props.newsDetail.body}} />
                        </div>
                    </div>
                    {this.props.newsDetail.related_news.length ? 
                        <div className="last-crack-game">
                            <h5 className="unreleased-game-title">{STRINGLOCAL.otherNews}</h5>
                            {this.props.newsDetail.related_news.map((objPrevNews, i) => (
                                <div className="news-wrapper" key={i}>
                                    <div className="news-list news-list-detail">
                                        <a href={'/newsdetail-steam-' + objPrevNews.slug}>
                                            <div className="news-block">
                                                <p className="news-list-title">{objPrevNews.title}</p>
                                                <h6 className="short-desc" dangerouslySetInnerHTML={{__html: objPrevNews.preview}} />
                                            </div>
                                        </a>    
                                    </div>
                                </div>
                            ))}
                        </div> : ''
                    }
                    <div className="last-crack-game">
                        <h5 className="unreleased-game-title">{STRINGLOCAL.recentlyCrackedGames}</h5>
                        <div className="cracked-game-list">
                            {this.props.latestCrackedGame.map((obj, i) => {
                                return (
                                    <div className="small-game-cont" key={i}>
                                        <div className="image-wrapper">
                                            <a href={'/' + obj.slug}>
                                                <img src={obj.short_image} alt={obj.title} />
                                            </a>
                                            <h2 className="game-name">{obj.title.slice(0, 28)}</h2>
                                            <h3 className="label-game-status game-cracked-home game-cracked-yes-home">{obj.readable_status}</h3>
                                            <div className="release-date release-date-small">
                                                <img src="/calendar-1.svg" />
                                                <span>{obj.release_date.slice(0, 10)}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <BottomInfo />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        newsDetail: state.newsObj,
        latestCrackedGame: state.latestCrackedGame,
        prevNews: state.prevNews,
        userLang: state.userLang,
        detail_game: state.detail_game,
    }
}

export default connect(mapStateToProps)(NewsDetailSteam);