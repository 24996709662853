import React, { Component } from 'react';

import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';

import TopBar from '../top-bar/top-bar.js';

import './about-us.css';

class AboutUs extends Component {
    // componentDidMount() {
    //     const s = document.createElement('script');
    //     s.src = 'https://telegram.org/js/telegram-widget.js?22'
    //     s.setAttribute('data-comments-limit', 5)
    //     s.setAttribute('data-telegram-discussion', "gamestatusinfo/759")
    //     s.setAttribute('data-color', "1B8")
    //     s.setAttribute('data-dark', 1)
    //     s.async = true;
    //     this.instance.appendChild(s);
    //   }
    render() {
        return <div ref={el => (this.instance = el)} />;
        // return (
            // <div className="about-us-wrapper">
            //     <Helmet>
            //         <link rel='canonical' href='https://gamestatus.info/about-us' data-react-helmet='true' />
            //     </Helmet>
            //     <div className="about-us-main">
            //         <TopBar />
            //         <img className="logo" src="./logo.svg" alt="gamestatus.info" />
            //         <h1>GAMESTATUS</h1>
            //         <p className="about-us-text">
            //             Добро пожаловать на сайт Gamestatus.info!
            //             Именно у нас вы сможете узнать все про новейшие компьютерные игры. Информация о взломе игр — основная фишка, 
            //             которая отличает Gamestatus.info среди других ресурсов. Для удобства поиска и упрощения с работы с сайтом, 
            //             каждый пользователь может использовать календарь игр. В календаре игр вы всегда найдёте актуальные новости про взлом игр, 
            //             которые уже вышли. Кроме того, здесь можно увидеть всю информацию о свежайших игровых релизах и предварительные даты их взлома.
            //         </p>
            //         <img className="about-us-main-img" src="./about-us-calendar.jpeg" alt="gamestatus игровой календарь" />
            //         <p className="about-us-text">
            //             На главной странице сайта часто публикуются игровые новости, потому ни один геймер не пропустит выход долгожданных новинок для пк.
            //             Игровые новости, совместно с другими разделами сайта, помогут каждому пользователю узнать все нюансы о предстоящих релизах игр, информацию про их взлом, 
            //             особенности игрового процесса, а также системные требования лучших компьютерных игр.
            //         </p>
            //         <img className="about-us-main-img" src="./about-us-home-page.jpeg" alt="gamestatus игры 2020 год" />
            //         <p className="about-us-text">
            //             К сожалению, довольно большое число фанатов компьютерных игр не имеет возможности поиграть в топовые игры 2020 года. 
            //             Взлом игр — это как раз то, что позволит каждому из нас насладиться увлекательным игровым миром. Помимо этого, 
            //             взлом игры позволяет обойти покупку лицензионной версии, что также является несомненным плюсом. Информацию о взломе 
            //             самых актуальных игр 2020 года можно найти на главной странице сайта или в календаре игр.
            //         </p>
            //         <p className="about-us-text">
            //             Современная тенденция индустрии компьютерных игр — внедрение новых технологий в виртуальный мир шутеров, rpg и т. д. 
            //             Все это значительно улучшает игровой процесс и делает компьютерную игру более увлекательной для прохождения. 
            //             Наряду с этим, улучшение технологий приводит к резкому подъему цен на игры, особенно, на топ-релизы 2020 года. 
            //             Геймер сталкивается с двумя потенциально возможными вариантами развития событий: покупка игры или ожидание её взлома.
            //         </p>
            //         <p className="about-us-text">
            //             Актуальную информацию про взлом игр, календарь игр, а также самые свежие игровые новости вы всегда найдёте только на Gamestatus.info!
            //         </p>
            //         <Link to='/'><p className="home-button">На Главную</p></Link>
            //     </div>
            // </div>
        // )
    }
}
export default AboutUs;