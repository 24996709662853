import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

import '../loginpage/login-page.css';
import './reset-password.css';
import { HOST } from '../../constants.js';


class ResetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {mailSent: false, errEmail: false}
        this.emailRef = React.createRef();
    }

    componentDidMount() {
        document.title = 'Сброс пароля | Gamestatus'
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.resetPassHundler()
        }
    }

    resetPassHundler = () => {
        let email = this.emailRef.current.value
        if (email.length) {
            this.setState({errEmail: false})
        } else {
            this.setState({errEmail: true})
            return
        }

        let data = {"email": email}
        fetch(HOST + 'auth/user/reset_password/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }})
            this.setState({mailSent: true})
    }

    render() {
        if (this.state.mailSent) {
            return (
                <div className='main'>
                    <div className='content'>
                        <Link to='/'>
                            <div className='sign-in-wellcome'>
                                <img className='sign-in-logo' src='/logo.svg' />
                                <p className='sign-in-title'>GAMESTATUS</p>
                            </div>
                        </Link>
                        <div className='sign-in-block reset-pass-complete'>
                            <p>Если вы ввели существующий email, вы получите письмо с 
                                дальнейшими инструкциями на ваш электронный адрес.
                            </p>
                            <Link to='/'><span className='sign-in-green-text'> На главную</span></Link>
                        </div>
                    </div>
                </div>
            )
        }

        return (
            <div className='main' onKeyDown={this.handleKeyDown}>
                <Helmet>
                    <link rel='canonical' href='https://gamestatus.info/reset-password' data-react-helmet='true' />
                </Helmet>
                <div className='content'>
                    <Link to='/'>
                        <div className='sign-in-wellcome'>
                            <img className='sign-in-logo' src='/logo.svg' />
                            <p className='sign-in-title'>GAMESTATUS</p>
                        </div>
                    </Link>
                    <div className='sign-in-block reset-password'>
                        <p className='sign-in-text reset-pass-text'>Восстановление пароля</p>
                        <input 
                            className={this.state.errEmail ? 'input-field red-borad' : 'input-field'}
                            type='email' placeholder='Почта' ref={this.emailRef}
                        />
                        <p className='sign-in-button' onClick={this.resetPassHundler}>Восстановить пароль</p>
                        <p className='sign-in-white-text'>У вас нет аккаунта?
                            <Link to='/registration'><span className='sign-in-green-text'> Регистрация</span></Link>
                        </p>
                        <Link to='/sign-in'><p className='sign-in-white-text sign-in-green-text'>Войти</p></Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ResetPassword;