import React, { Component } from 'react';

import { connect } from 'react-redux';

import './calendar-date.css';

class CalendarDate extends Component {

    constructor(props) {
        super(props)
        this.state = {dateStr: ''}
        this.userLocale = this.props.userLang
    }

    componentDidMount() {
        let date = new Date()
        let monthNum = date.getMonth()
        if (this.userLocale.includes('ru')) {
            var nameMonth = {
                0: 'Января', 1: 'Февраля', 2: 'Марта', 3: 'Апреля',
                4: 'Мая', 5: 'Июня', 6: 'Июля', 7: 'Августа',
                8: 'Сентября', 9: 'Октября', 10: 'Ноября',
                11: 'Декабря'
            }
        } else {
            var nameMonth = {
                0: 'January', 1: 'February', 2: 'March', 3: 'April',
                4: 'May', 5: 'June', 6: 'July', 7: 'August',
                8: 'September', 9: 'October', 10: 'November',
                11: 'December'
            }
        }
        let strDate = `${date.getDate()} ${nameMonth[monthNum]}`
        this.setState({dateStr: strDate})
    }

    render() {
        return (
            <div className="date-wrapper">
                <img src="/calendar-1.svg" />
                <span className="date">{this.state.dateStr}</span>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang,
    }
}

export default connect(mapStateToProps)(CalendarDate);
