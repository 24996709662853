import React, { Component } from 'react';
import { connect } from 'react-redux';

import './top-bar.css'


class OfflineActBanner extends Component {
    render() {
        if (this.props.userLang.includes('ru')) {
            if (window.innerWidth > 750) {
                return(
                    <div className='offline-act-banner'>
                        <a href="https://denuvo.net/">
                        <img src="https://f000.backblazeb2.com/file/GameStatus/a_offline_act_banner/bannerfulres+(1).jpg" />
                        </a>
                    </div>
                )
            } else {
                return(
                    <div className='offline-act-banner'>
                        <a href="https://denuvo.net/">
                        <img src="https://f000.backblazeb2.com/file/GameStatus/a_offline_act_banner/a_offline_act_banner_bannerfulres+1+mob+(1).png" />
                        </a>
                    </div>
                )
            }
        } else {
            if (window.innerWidth > 750) {
                return(
                    <div className='offline-act-banner'>
                        <a href="https://denuvo.net/">
                        <img src="https://f000.backblazeb2.com/file/GameStatus/a_offline_act_banner/var1enghighres+(1)_en.jpg" />
                        </a>
                    </div>
                )
            } else {
                return(
                    <div className='offline-act-banner'>
                        <a href="https://denuvo.net/">
                        <img src="https://f000.backblazeb2.com/file/GameStatus/a_offline_act_banner/var1enghighres+min_en+(1).png" />
                        </a>
                    </div>
                )
            }
        }
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang
    }
}

export default connect(mapStateToProps)(OfflineActBanner);
