import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';

import './login-page.css';
import { HOST, COOKIES, FACEBOOKLOGIN, VKLOGIN, STRINGLOCAL } from '../../constants.js';
import TelegramLoginButton from 'react-telegram-login';


class LoginPage extends Component {

    constructor(props) {
        super(props)
        this.state = {errLoginMess: '', errLogin: false, errPass: false}
        this.loginInput = React.createRef();
        this.passInput = React.createRef();
        this.userLocale = this.props.userLang
    }

    componentDidMount() {
        let cat = COOKIES.get('jwt_token')
        if (cat) {
            this.props.history.push('/')
        }
        document.title = 'Вход | Gamestatus'
        var currentHref = window.location.href
        if (currentHref.split('oauthErr=')[1]) {
            this.setState({errLoginMess: STRINGLOCAL.login_error})
        }
    }

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
          this.loginHundler()
        }
    }

    loginHundler = () => {
        let login = this.loginInput.current.value
        let pass = this.passInput.current.value

        if (login.length) {
            this.setState({'errLogin': false})
        } else {
            this.setState({'errLogin': true})
        }

        if (pass.length) {
            this.setState({'errPass': false})
        } else {
            this.setState({'errPass': true})
        }

        let data = {'username': login, 'password': pass}

        fetch(HOST + 'auth/api-token-auth/', {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }})
            .then(response => response.json())
            .then(json => {
                let err = json['non_field_errors']
                if (err) {
                    this.setState({errLoginMess: err})
                    return
                }
                let token = json['token']
                if (token) {
                    COOKIES.set('jwt_token', token, { path: '/', maxAge: 365*24*60*60 })
                    this.props.history.push('/')
                }
            })
    }

    onTelegramAuth = (userData) => {
        fetch(HOST + 'auth/user/login_tg_user/', {
            method: 'POST',
            body: JSON.stringify(userData),
            headers: {
                'Content-Type': 'application/json'
            }})
            .then(response => response.json())
            .then(json => {
                let redirectLink = json["redirect_to"]
                window.location.replace(redirectLink)
            })
    }

    needToLogin = () => {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const param = params.get('req_login');
        if (param) {
            return <p className='err-text'>{STRINGLOCAL.needLoginSponsor}</p>
        }
    }

    render() {
        return (
            <div className='main' onKeyDown={this.handleKeyDown}>
                <Helmet>
                    <link rel='canonical' href='https://gamestatus.info/sign-in' data-react-helmet='true' />
                </Helmet>
                <div className='content'>
                    <Link to={'/' + this.userLocale}>
                    </Link>
                    <div className='sign-in-block'>
                        <div className="title-logo">
                            <img src="/logo_new.png" alt="gamestatus.info" />
                        </div>
                        <div className='social-login'>
                            <p className='sign-in-text'>{STRINGLOCAL.socialLogin}</p>
                            {this.needToLogin()}
                            <TelegramLoginButton dataOnauth={this.onTelegramAuth} botName="gs_notifier_bot" className="login-tg-btn" usePic="false" />
                            <div className="wrapper-social-icons">
                                {/* <a href={FACEBOOKLOGIN} className="facebook-login">
                                    <img src="/fb_icon.png" alt="Войти с помощью Facebook" />
                                    <span>{STRINGLOCAL.socialLogin} Facebook</span>
                                </a> */}
                                <a href={VKLOGIN} className="vk-login">
                                    <img src="/vk_icon.png" alt="Войти с помощью Вконтакте" />
                                    <span>{STRINGLOCAL.socialLogin} VK</span>
                                </a>
                            </div>
                            {/* <span className='sign-in-text old-method'>{STRINGLOCAL.oldlLogin}</span>
                            <input 
                                className={this.state.errLogin ? 'input-field red-borad' : 'input-field'} 
                                type='email' placeholder='login' ref={this.loginInput} 
                            /> */}
                            {/* <input 
                                className={this.state.errPass ? 'input-field red-borad' : 'input-field'} 
                                type='password' placeholder='password' ref={this.passInput} 
                            /> */}
                            {/* <p className='sign-in-button' onClick={this.loginHundler}>{STRINGLOCAL.signIn}</p> */}
                            <p className='err-text'>{this.state.errLoginMess}</p>
                            <sapn className='privacy-policy-text'><a href='/privacy-policy'>Privacy Policy</a></sapn>
                        </div>
                        {/* <p className='sign-in-white-text'>У вас нет аккаунта?
                            <Link to='/registration'>
                                <span className='sign-in-green-text'> Регистрация</span>
                            </Link>
                        </p>
                        <Link to='/reset-password'>
                            <p className='sign-in-white-text sign-in-green-text'>Восстановить пароль</p>
                        </Link> */}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang,
    }
}

export default connect(mapStateToProps)(LoginPage);
