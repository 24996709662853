import React, { Component } from 'react';

import { connect } from 'react-redux';
import {clearCache} from "clear-cache"
import { COOKIES } from '../../constants.js';

import { Link } from 'react-router-dom'
import getUserLocale from 'get-user-locale';
import { STRINGLOCAL, VERSION_SITE } from '../../constants.js';

import './bottom-info.css'

class BottomInfo extends Component {
    constructor(props) {
        super(props)
        this.userLocale = this.props.userLang
    }

    componentDidMount() {
        let s_version = COOKIES.get('s_version')
        if (s_version != VERSION_SITE) {
            clearCache()
            COOKIES.set('s_version', VERSION_SITE, { path: '/' })
        }
    }

    render() {
        return (
            <div className='bottom-info-wrapper'>
                <div className='bottom-info'>
                    <h1>
                        {STRINGLOCAL.bottom_main_desc}
                    </h1>
                    <p className='mail-info'>
                        <a href='mailto:gamestatusnotifier@gmail.com'>{STRINGLOCAL.bottom_communication} gamestatcommunication@gmail.com</a>
                    </p>
                    {/* {this.userLocale.includes('ru') ? <Link to='/about-us'><p className="about-us">About Us</p></Link> : ''} */}
                    <p className='aditional-bottom-info'>Thanks for the information provided: crackwatch.com, metacritic, mmo13</p>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLang: state.userLang,
    }
}

export default connect(mapStateToProps)(BottomInfo);