import React, { Component } from 'react';

import { withRouter } from "react-router-dom";
import DetailGame from "./components/detail-game/detail-game.js";
import NewsDetail from './components/news-detail/news-detail.js';
import NewsDetailSteam from './components/news-detail-steam/news-detail-steam.js';
import PurchaseGame from './components/purchase-game/purchase-game.js'


class MainRouer extends Component {


    // componentDidMount() {
    //     let path = window.location.href
    //     let subPath = path.split('/')
    //     path = subPath[subPath.length - 1]
    //     if (path.includes('news-detail'))
    // }

    render() {
        let path = window.location.href
        let subPath = path.split('/')
        path = subPath[subPath.length - 1]
        if (path.includes('_newsdetail')) {
            return (
                <NewsDetail />
            )
        } else if (path.includes('newsdetail-steam')) {
            return (
                <NewsDetailSteam />
            )
        } else if (path.includes('newsdetail')) {
            return (
                <NewsDetail />
            )
        } else if (path.includes('purchasegame')) {
            return (
                <PurchaseGame />
            )
        }
        else {
            return (
                <DetailGame />
            )
        }
    }

}

export default withRouter(MainRouer);