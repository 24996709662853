import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';

import { COOKIES, HOST } from '../../constants.js';
import Loader from '../loader/loader.js';

class RemoveAdsRedirect extends Component {
    constructor(props) {
        super(props)
        this.state = {
            load: true
        }
    }

    componentDidMount() {
        let jwtToken = COOKIES.get('jwt_token');
        if (jwtToken) {
            fetch(HOST + 'auth/user/disable_ads/', {
                method: "POST",
                credentials: 'omit',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': 'JWT ' + jwtToken
                }
            })
        }
        COOKIES.set('is_active_ads', 0, { path: '/' })
        this.setState({load: false})
    }

    render() {
        let jwtToken = COOKIES.get('jwt_token');
        if (this.state.load) {
            return (
                <div className="home-main-container loader-distance">
                    <Loader />
                </div>
            )
        } else {
            if (!jwtToken) {
                return <Redirect to="/sign-in/?req_login=true" />
            } else {
                return <Redirect to="/" />
            }
        }
        
    }
}

export default RemoveAdsRedirect;


