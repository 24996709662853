import React, { Component } from 'react';

import { Link } from 'react-router-dom'

import '../loginpage/login-page.css'
import '../resetpassword/reset-password.css'
import './confirm-email.css'


class ConfirmComplete extends Component {

    componentDidMount() {
        document.title = 'Регистрация завершена | Gamestatus'
    }

    render() {
        return (
            <div>
                <div className='main'>
                    <div className='content'>
                        <Link to='/'>
                            <div className='sign-in-wellcome'>
                                <img className='sign-in-logo' src='/logo.svg' />
                                <p className='sign-in-title'>GAMESTATUS</p>
                            </div>
                        </Link>
                        <div className='sign-in-block reset-pass-complete'>
                            <p>Спасибо за регистрацию.
                            </p>
                            <Link to='/sign-in'><span className='sign-in-green-text'> Авторизироваться</span></Link>
                            <Link to='/'><span className='sign-in-green-text'> На главную</span></Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default ConfirmComplete;