import React, { Component } from 'react';


class ImageLoader extends Component {
    constructor(props) {
        super(props)
        this.state = {loaded: false}
    }

    setImg = (newsObj) => {
        if (newsObj.source == "youtube") {
            return newsObj.preview_youtube
        } else {
            return newsObj.preview
        }
    }


    render() {
        return (
            <div className={this.props.data.source == "original" ?"news-backgroud" : "news-backgroud news-backgroud-youtube"}>
                <img 
                    className={this.state.loaded ? "news-image image-loaded" : "news-image image-load"}
                    src={this.setImg(this.props.data)} 
                    alt={this.props.data.title} 
                    onLoad={() => {this.setState({loaded: true})}} 
                />
            </div>
        )
    }
}
export default ImageLoader;