import React, { Component } from 'react';

import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import './profile.css'
import TopBar from '../top-bar/top-bar.js'
import { HOST, COOKIES, STRINGLOCAL } from '../../constants.js'
import Loader from '../loader/loader.js'
import Store from '../../modules/store.js'


class Profile extends Component {

    constructor(props) {
        super(props)
        this.state = {isLoad: false}
        this.jwt = COOKIES.get('jwt_token')
        this.userLocale = this.props.userLang;
    }

    getUserInfo = () => {
        let params = this.jwt ? { headers: {
            "Content-Type": "application/json",
            'Authorization': 'JWT ' + this.jwt,
            "Accept-Language": this.userLocale,
            "Content-Language": this.userLocale,
        }} : {headers: {
            "Content-Type": "application/json",
            "Accept-Language": this.userLocale,
            "Content-Language": this.userLocale,
        }}
        fetch(HOST + 'auth/user/', params)
            .then(response => response.json())
            .then(json => {
                Store.dispatch({type: 'USERPROFILE', value: json})
                this.setState({isLoad: true})
            })
            .catch(error => {
                return Promise.reject()
            })
    }

    componentDidMount() {
        if (!this.jwt) {
            this.props.history.push('/')
        }
        this.getUserInfo()
        document.title = STRINGLOCAL.your_profile
    }

    render() {
        if (!this.state.isLoad) {
            return (
                <div className='detail-profile'>
                    <TopBar />
                    <Loader />
                </div>
            )
        }

        return (
            <div className='detail-profile'>
                <TopBar />
                <div className='title-navigator'>
                    <Link to='/'><p>{STRINGLOCAL.home_page}</p></Link>
                    <p> > </p>
                    <p>{STRINGLOCAL.your_profile}</p>
                </div>
                <div className='profile-wrapper'>
                    <div className='avatar-incon'>
                        <img src='/man-user.svg' />
                    </div>
                    <div className='profile-info'>
                        <div>
                            <p className='text-key-profile'>{STRINGLOCAL.username}</p>
                            <p className='text-value'>{this.props.user_profile.username}</p>
                        </div>
                        <div className='delimiter'></div>
                        <div>
                            <p className='text-key-profile'>{STRINGLOCAL.reg_date}</p>
                            <p className='text-value'>{this.props.user_profile.date_joined.substr(0, 10)}</p>
                        </div>
                        {/* <div className='delimiter'></div>
                        <div>
                            <p className='text-key-profile'>{STRINGLOCAL.subs_list}</p>
                            <p className='text-value'>{this.props.user_profile.count_subscriptions}</p>
                        </div>
                        <div className='delimiter'></div>
                        <div>
                            <p className='text-key-profile'>{STRINGLOCAL.comment_count}</p>
                            <p className='text-value'>{this.props.user_profile.count_comment}</p>
                        </div>
                        <div className='delimiter'></div>
                        <div>
                            <p className='text-key-profile'>{STRINGLOCAL.rate_count}</p>
                            <p className='text-value'>{this.props.user_profile.user_rate}</p>
                        </div>
                        {!this.props.user_profile.is_social ?
                            <Link to='/change-password'>
                                <p className='change-pass'>{STRINGLOCAL.change_pass}</p>
                            </Link>
                            : ''
                        } */}
                    </div>
                </div>
                <div className='subscription-block'>
                    <p className='subscribe-text'>{STRINGLOCAL.your_subs}</p>
                    <div className='subscriptions-list'>
                        {this.props.user_profile.subscriptions_list.length ? 
                            this.props.user_profile.subscriptions_list.map((obj, i) => {

                                let releasedGameInfo = obj.readable_status.indexOf('Не')
                                let releasedGameInfoEn = obj.readable_status.indexOf('Not')
                                let releasedGame = obj.readable_status.indexOf('РЕЛИЗ')
                                let releasedGameEn = obj.readable_status.indexOf('REL')
                                if (releasedGameInfo != -1 || releasedGameInfoEn != -1) {
                                    obj.crackedGame = 'game-cracked-no-home'
                                } else if (releasedGame != -1 || releasedGameEn != -1) {
                                    obj.crackedGame = 'game-cracked-un-home'
                                } 
                                else {
                                    obj.crackedGame = 'game-cracked-yes-home'
                                }
            
                                return (
                                    <div className="small-game-cont-user" key={i}>
                                        <Link to={`/${obj.slug}/${this.userLocale}`}>
                                            <img src={obj.short_image} />
                                        </Link>
                                        <h2 className="game-name">{obj.title.substr(0, 27)}</h2>
                                        <h3 className={"label-game-status-prof game-cracked-profile " + obj.crackedGame}>
                                            {obj.readable_status}
                                        </h3>
                                        <div className="release-date-profile release-date-small-profile">
                                            <img src='/calendar-1.svg' />
                                            <span>{obj.release_date.substr(0, 10)}</span>
                                        </div>
                                    </div>
                                )
                            }) : <p className='text-key-profile miss-subscription'>{STRINGLOCAL.no_subs_list}</p>}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        is_auth: state.is_auth,
        user_profile: state.user_profile,
        userLang: state.userLang,
    }
}

export default connect(mapStateToProps)(Profile);