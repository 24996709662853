import './side-right-ads.css';

import React, { Component } from 'react';
import { Adsense } from '@ctrl/react-adsense';

import { COOKIES } from '../../constants.js';


class RightSideAds extends Component {

    render() {
        let isActiveAds = COOKIES.get('is_active_ads');
        if (parseInt(isActiveAds) != 0) {
            if (window.innerWidth < 1830) {
                return (
                    <div className="ads-right-block">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="7553760300"
                            style={{ display: "block", width: "160px", height: "600px"}}
                        />
                    </div>
                )
            } else {
                return (
                <div className="ads-right-block">
                    <Adsense
                        client="ca-pub-6277942134397670"
                        slot="9058667257"
                    />
                </div>
                )
            }
        } else {
            return ""
        }
    }
}
export default RightSideAds;
