import React, { Component } from 'react';

import { connect } from 'react-redux';
import ScriptTag from 'react-script-tag';
import { Adsense } from '@ctrl/react-adsense';

import './game-calendar.css';
import TopBar from '../top-bar/top-bar.js';
import { HOST, STEAMPAYREFERRAL, COOKIES, REFLINK, STRINGLOCAL } from '../../constants.js';
import Loader from '../loader/loader.js';
import Store from '../../modules/store.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import MiddleAds from '../middle-ads/middle-ads.js';
import MiddleAdsShort from '../middle-ads/middle-ads-chort.js';
import { Link } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { returnRefGameImg, getAnotherLangPage } from '../utils';


class GameCalendar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            loaded: false, selectOneMonth: false, selectSecondMonth: false,
            selectThirdMonth: false, selectFourthMonth: false, selectFifthMonth: false,
            selectSixthMonth: false, selectSeventhMounth: false
        }
        this.maxWidth = window.innerWidth;
        this.imgRefGame = returnRefGameImg(this.maxWidth);
        this.userLocale = this.props.userLang;
    }

    clickOnRefGame = () => {
        fetch(HOST + 'gameinfo/game/countrefgame/')
    }

    componentDidMount() {
        this.getGameCalendar()
    }

    getGameCalendar() {
        fetch(HOST + 'gameinfo/game/gamecalendar/')
            .then(response => response.json())
            .then(json => {
                for (let key in json.response_game_calendar) {
                    if (json.response_game_calendar[key].length) {
                        Store.dispatch({type: 'GAMEMONTHCALENDAR', value: json.response_game_calendar[key]})
                        break
                    }
                }
                Store.dispatch({type: 'GAMECALENDAR', value: json.response_game_calendar})
                Store.dispatch({type: 'REFGAME', value: json.ref_game})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.response_game_calendar.count_tg_users})
                this.setState({loaded: true, selectOneMonth: true})
            })
    }

    setActiveMonth(numMonth, monthName) {
        let monthList = {
            selectOneMonth: false, selectSecondMonth: false,
            selectThirdMonth: false, selectFourthMonth: false, selectFifthMonth: false,
            selectSixthMonth: false, selectSeventhMounth: false
        }
        monthList[numMonth] = true
        this.setState(monthList)
        Store.dispatch({type: 'GAMEMONTHCALENDAR', value: this.props.gameCalendar[0][monthName]})
    }

    handlerSteamIcon(gameObj) {
        if (gameObj.steam_prod_id) {
            return <div className='steam-icon'><a target="_blank" href={`https://store.steampowered.com/app/${gameObj.steam_prod_id}`}><img src="https://gamestatus.s3.us-west-000.backblazeb2.com/Steam_icon_logo.svg+(1).png"/></a></div>
        }
    }
    

    render() {
        let countMounth = 0
        let countGame = 0
        let showRefGame = true
        if (!this.state.loaded) {
            return (
                <div className='main-wrapper'>
                    <div className="home-main-container">
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <div className='main-wrapper'>
                {/* <LeftSideAds />
                <RightSideAds /> */}
                <Helmet>
                    <title>{STRINGLOCAL.site_name}</title>
                    <link rel="alternate" hreflang={getAnotherLangPage(this.userLocale)} href={`https://gamestatus.info/gamecalendar/${getAnotherLangPage(this.userLocale)}`} />
                    <link rel='canonical' href={`https://gamestatus.info/gamecalendar/${this.userLocale}`} data-react-helmet='true' />
                    <meta property="og:description" content={STRINGLOCAL.calendar_desc} data-react-helmet='true' />
                    <meta name="description" content={STRINGLOCAL.calendar_desc} />
                    <meta property="og:image" content="https://gamestat.s3.eu-west-2.amazonaws.com/logo_preview.png" data-react-helmet='true' />
                    <meta property="og:title" content={STRINGLOCAL.calendar_pg_title} data-react-helmet='true' />
                    <meta property="og:site_name" content={STRINGLOCAL.site_name} data-react-helmet='true' />
                    <script async custom-element="amp-ad" src="https://cdn.ampproject.org/v0/amp-ad-0.1.js"></script>
                </Helmet>
                <div className="home-main-container cracked-games main-background">
                    <TopBar countTgUsers={this.props.countTgUsers} hideCalendar={true} />
                    <div id="advideo_adv_container"></div>
                    <img className="calendar-logo" src='/game-calendar-logo.png' alt="График выхода игр на ПК 2021 год" />
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    {/* <MiddleAds /> */}
                    <h1 className="calendar-title">{STRINGLOCAL.games_release_schedule}</h1>
                    <div className="calendar-wrapper">
                        <ul className="list-month">
                            {Object.keys(this.props.gameCalendar[0]).map((monthName, i) => {
                                if (this.props.gameCalendar[0][monthName].length) {
                                    countMounth += 1
                                    if (countMounth == 1) {
                                        return (<li key={i} 
                                            className={this.state.selectOneMonth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectOneMonth', monthName)}
                                            >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    } else if (countMounth == 2) {
                                        return (<li key={i} 
                                            className={this.state.selectSecondMonth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectSecondMonth', monthName)}
                                        >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    } else if (countMounth == 3) {
                                        return (<li key={i} 
                                            className={this.state.selectThirdMonth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectThirdMonth', monthName)}
                                            >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    } else if (countMounth == 4) {
                                        return (<li key={i} 
                                            className={this.state.selectFourthMonth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectFourthMonth', monthName)}
                                            >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    } else if (countMounth == 5) {
                                        return (<li key={i} 
                                            className={this.state.selectFifthMonth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectFifthMonth', monthName)}
                                            >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    } else if (countMounth == 6) {
                                        return (<li key={i} 
                                            className={this.state.selectSixthMonth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectSixthMonth', monthName)}
                                            >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    } else if (countMounth == 7) {
                                        return (<li key={i} 
                                            className={this.state.selectSeventhMounth ? "selct-month" : ""}
                                            onClick={() => this.setActiveMonth('selectSeventhMounth', monthName)}
                                            >{this.userLocale.includes('ru') ? this.props.gameCalendar[0]["translated_month"][monthName] : monthName}</li>)
                                    }
                                }
                            })}
                        </ul>
                        <div className="calendar-game-wrapper">
                            <div className="describe-color">
                                <div className="blue-calendar"></div>
                                <span>{STRINGLOCAL.usual_game}</span>
                                <div className="red-calendar"></div>
                                <span>{STRINGLOCAL.aaa_game}</span>
                            </div>
                            <div className="game-in-calendar">
                                {this.props.gameClMonth.map((obj, i) => {
                                    countGame += 1
                                    if (obj.is_AAA) {
                                        return (
                                                <div className="game-cl-us game-cl-aaa">
                                                    <Link to={'/' + obj.slug + "/" + this.userLocale} key={i}>
                                                        <img className="calenad-img-game" src={obj.short_image} alt={`Дата выхода ${obj.title}, технические характеристики`} />
                                                    </Link>
                                                    <div className="game-cl-rl-date">
                                                        <img src="/calendar-1.svg" />
                                                        <p>{obj.release_date}</p>
                                                        {this.handlerSteamIcon(obj)}
                                                    </div>
                                                    <p className="info-button">></p>
                                                    <p className="num-game">#{countGame}</p>
                                                </div>
                                        )
                                    } else {
                                        return (
                                                <div className="game-cl-us">
                                                    <Link to={'/' + obj.slug + "/" + this.userLocale} key={i}>
                                                        <p className="cl-title-game">{obj.title}</p>
                                                    </Link>
                                                    <div className="game-cl-rl-date">
                                                        <img src="/calendar-1.svg" />
                                                        <p>{obj.release_date}</p>
                                                        {this.handlerSteamIcon(obj)}
                                                    </div>
                                                    <p className="info-button">></p>
                                                    <p className="num-game">#{countGame}</p>
                                                </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                    <h2 className="text-hot-game small-text">
                        {STRINGLOCAL.calendar_bottom_text}
                    </h2>
                    <BottomInfo />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        gameCalendar: [state.game_calendar],
        gameClMonth: state.game_cl_month,
        refGame: state.refGame,
        userLang: state.userLang,
        countTgUsers: state.tgCountUsers,
    }
}

export default connect(mapStateToProps)(GameCalendar);