import React, { Component } from 'react';

import { connect } from 'react-redux';
import Store from '../../modules/store.js';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faYoutube} from '@fortawesome/free-brands-svg-icons';
import LeftSideAds from '../side-left-ads/side-left-ads.js';
import RightSideAds from '../side-right-ads/side-right-ads';
import MiddleAds from '../middle-ads/middle-ads.js';
import { Adsense } from '@ctrl/react-adsense';

import './news.css';
import TopBar from '../top-bar/top-bar.js';
import BottomInfo from '../bottom-info/bottom-info.js';
import { HOST, DEBUG, STRINGLOCAL } from '../../constants.js';
import Loader from '../loader/loader.js';
import ImageLoader from '../img-loader/img-loader.js'

class News extends Component {

    constructor(props) {
        super(props)
        this.state = {loaded: false}
        this.hasMore = true
        this.nextPage = null
        this.userLocale = this.props.userLang
    }

    componentDidMount() {
        this.getNews()
    }

    componentWillUnmount() {
        Store.dispatch({type: 'NEWSLIST', value: []})
    }

    getNews = () => {
        var headerInfo = { headers: {
            'Content-Type': 'application/json',
            'Accept-Language': this.userLocale,
            'Content-Language': this.userLocale,
        }}
        var url = this.nextPage || HOST + 'news/'
        if (!DEBUG && !url.includes('https')) {
            url = url.replace('http', 'https')
        }
        fetch(url, headerInfo)
            .then((response) => response.json())
            .then(json => {
                this.nextPage = json.next
                if (!this.nextPage) {
                    this.hasMore = false
                }
                let newsListResponse = this.props.newsList.slice()
                newsListResponse.push(...json.results.list)
                Store.dispatch({type: 'NEWSLIST', value: newsListResponse})
                Store.dispatch({type: 'TGCOUNTUSERS', value: json.results.count_tg_users})
                this.setState({loaded: true})
            })
    }

    render() {
        if (!this.state.loaded) {
            return (
                <div className="news-wrapper">
                    <div className="news-main">
                        <Loader />
                    </div>
                </div>
            )
        }
        return (
            <div className="news-wrapper">
                {/* <LeftSideAds />
                <RightSideAds /> */}
                <Helmet>
                    <title>{STRINGLOCAL.gsNewsListTitle}</title>
                    <link rel="canonical" href="https://gamestatus.info/news" data-react-helmet="true" />
                    <meta property="og:description" content={STRINGLOCAL.gsNewsDescription} />
                    <meta name="description" content={STRINGLOCAL.gsNewsDescription} />
                </Helmet>
                <div className='news-main'>
                    <TopBar countTgUsers={this.props.countTgUsers}  hideNews={true} />
                    {/* <div className="ads-wrapper">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="6713999859"
                            style={{ display: 'inline-block', width: "728px", height: "90px"}}
                        />
                    </div>
                    <div className="ads-wrapper ads-wrapper-mini">
                        <Adsense
                            client="ca-pub-6277942134397670"
                            slot="2570385490"
                            style={{ display: 'inline-block', width: "350px", height: "120px"}}
                        />
                    </div> */}
                    {/* <MiddleAds /> */}
                    <div className="news-list">
                        <InfiniteScroll
                            dataLength={this.props.newsList.length}
                            hasMore={this.hasMore}
                            next={this.getNews}
                        >
                            {this.props.newsList.map((obj, i) => {
                                return (
                                    <div className="news-block" key={i}>
                                        {obj.source == 'youtube' ? <FontAwesomeIcon className="news-icon-youtube" icon={faYoutube} /> : ''}
                                        <a href={'/newsdetail/' + obj.slug + "/" + this.userLocale}>
                                            <ImageLoader data={obj} />
                                            {obj.source == 'youtube' ? '' : <h2 className="news-list-title">{obj.title}</h2>}
                                            </a>
                                    </div>
                                )
                            })}
                        </InfiniteScroll>
                    </div>
                </div>
                <BottomInfo />
            </div>
        )
    }
}
function mapStateToProps(state) {
    return {
        newsList: state.news_list,
        userLang: state.userLang,
        countTgUsers: state.tgCountUsers,
    }
}

export default connect(mapStateToProps)(News);