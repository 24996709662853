import React, { Component } from 'react';
import  { Redirect } from 'react-router-dom';

class ToRemoveAds extends Component {

    render() {
        window.location.replace("https://www.patreon.com/oauth2/become-patron?response_type=code&client_id=UtpWXRkKQb2nF0m53OJ5EIJA6AYYDv9xnUF69GSIXYpGRODosoc087Cb0TsBR_3R&redirect_uri=https://gamestatus.info/disabled-ads");
        return ""
    }
}

export default ToRemoveAds;


